function LimitedTimeOffer() {
  return (
    <div className="my-4 border text-center flex flex-col items-center lg:h-[50vh]">
      <p className="card-sm-text lg:text-[1.2rem] lg:pt-[2.5rem] pt-2 px-4">
        Limited-Time Offer! Don’t Miss Out
      </p>
      <p className="card-sm-text lg:text-[1.2rem] lg:pt-[2.5rem] pt-2 px-4">
        Enroll now to avail these massive discounts and secure your future in
        mobile app development!
      </p>
      <p className="card-sm-text lg:text-[1.2rem] lg:pt-[2.5rem] pt-2 px-4 pb-6">
        Seats Are Limited – Reserve Yours Today!
      </p>

      <div className="w-[70%] h-[40px] lg:h-[140px] bg-lcsBlue text-white rounded flex flex-col lg:gap-[1rem] items-center justify-center mb-5">
        <p className="card-sm-text lg:text-[1.2rem]  font-semibold">
          Course Start Date: January 10, 2025
        </p>
        <p className="card-sm-text lg:text-[1.2rem]  font-semibold">
          Application Deadline: December 31, 2024
        </p>
      </div>

      <button
        onClick={() =>
          window.open(
            "https://wa.me/917020999022?text=Hello%20I%20would%20like%20to%20enroll%20myself%20for%20React%20Native%20course",
            "_blank"
          )
        }
        className="bg-lcsBlue rounded card-sm-text lg:text-[1.2rem] h-[25px] lg:h-[50px] shadow-xl font-semibold w-[30%] mb-4  text-white"
      >
        Enroll Now
      </button>
    </div>
  );
}

export default LimitedTimeOffer;
