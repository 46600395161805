import React, { useState } from "react";
import blueLogo from "../../assets/imgs/xlogoBlue.png";
import lock from "../../assets/imgs/lock.svg";
import { toast } from "react-toastify";

import { isValidEmail, isDisposableEmail } from "../../utils/Utils";
import { useAuth } from "../../contexts/firebaseContext/Context";

function ForgotPassword() {
  const gifEmailSent = require("../../assets/imgs/email-sent-animation.gif");

  const { hrForgotPassword } = useAuth();

  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [sendLink, setSendLink] = useState<Boolean>(false);

  const validate = () => {
    let newError = "";

    if (!email) {
      newError = "Email is required";
    } else if (!isValidEmail(email)) {
      newError = "Invalid email format";
    } else if (isDisposableEmail(email)) {
      newError = "Disposable email addresses are not allowed";
    }

    setError(newError);
    return !newError;
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setLoading(true);
    try {
      await hrForgotPassword(email);
      setSendLink(true);
    } catch (error: any) {
      toast.error("An error occurred!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen overflow-hidden">
      <div className="bg-white w-full grid grid-cols-1 md:grid-cols-[1.2fr_2fr]">
        <div className="relative left-box bg-[#24336B] hidden md:flex flex-col items-center">
          <div className="md:w-[75%]">
            <div className="flex items-start my-[3rem]">
              <a href="/">
                <img
                  src={blueLogo}
                  className="h-[2rem] md:h-[3rem] rounded-md shadow-custom"
                  alt="Logo"
                />
              </a>
            </div>
            <div className="text-start">
              <h2 className="font-semibold md:text-[32px] text-[#fff]">
                Find the right candidate. Fast & Free
              </h2>
              <p className="font-medium md:text-[20px] text-[#ffffffc1]">
                Post your jobs to reach thousands of jobseekers for free
              </p>
            </div>
          </div>
        </div>

        <div className="right-box min-h-screen w-full flex flex-col justify-center items-center">
          {sendLink ? (
            <div className="text-center flex flex-col items-center gap-y-6 w-[80%] md:w-[65%] lg:w-[45%]">
              <h2 className="text-[30px] text-[#0F205F] font-semibold">
                Email Sent
              </h2>
              <img src={gifEmailSent} alt="" />
              <p className="text-[#000000a7] text-[18px]">
                We have sent an email to {email} with a link to verify and reset
                your password. Please check your inbox and click on the link to
                reset your password. If you don't see the email, be sure to
                check your spam or junk folder.
              </p>
            </div>
          ) : (
            <div className="text-center flex flex-col items-center gap-y-6 w-[80%] md:w-[65%] lg:w-[45%]">
              <h2 className="text-[30px] text-[#0F205F] font-semibold">
                Forgot Password ?
              </h2>
              <img className="w-[100px] h-[100px]" src={lock} alt="" />

              <p className="text-[#000000a7]">
                No worries! Just enter your email address below and we'll help
                you reset your password. You'll receive an email with
                instructions on how to create a new password.
              </p>
            </div>
          )}

          {sendLink ? (
            <>
              <p className="text-[14px] mt-3 md:mt-6 text-[#000000a0] font-medium">
                Didn't receive the email?{" "}
                <span
                  className="text-[#139AFD] cursor-pointer hover:underline"
                  onClick={handleFormSubmit}
                >
                  Resend
                </span>
              </p>
            </>
          ) : (
            <form
              onSubmit={handleFormSubmit}
              className="w-[90%] lg:w-[50%] mt-[2rem] space-y-8"
            >
              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="e. g. johnmilton1@gmail.com"
                  className="placeholder:text-[#000] placeholder:text-[10.63px] w-full text-md md:text-[10.63px] border border-[#1E1E1E] p-3 md:p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                {error && <p className="text-[12px] text-red-600">{error}</p>}
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[14px] text-white text-center py-4 rounded-md disabled:opacity-50"
                disabled={loading}
              >
                {loading ? "Sending..." : "Send link"}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
