import bubbleImg from "../../assets/imgs/bubble.svg";
import smallBubble from "../../assets/imgs/smallBubble.svg";
import scaleImg from "../../assets/imgs/scaleImg.svg";
import desktopImg from "../../assets/imgs/desktopImg.svg";
import bulbImg from "../../assets/imgs/bulbImg.svg";
import elctronIcon from "../../assets/imgs/elctronIcon.svg";
import bottleImg from "../../assets/imgs/bottleImg.svg";
import scaleN from "../../assets/imgs/scaleN.svg";
import nucleusImg from "../../assets/imgs/nucleusImg.svg";

function BackgroundImage() {
  return (
    <div className="lg:hidden">
      <img
        className="absolute top-4 left-7 h-4"
        src={bubbleImg}
        alt="bubbleImg"
      />
      <img
        className="absolute top-7 left-10 h-2"
        src={smallBubble}
        alt="bubbleImg"
      />
      <img
        className="absolute top-2 left-[11rem] h-10"
        src={scaleImg}
        alt="scaleImg"
      />
      <img
        className="absolute top-9 left-[15rem] h-5"
        src={desktopImg}
        alt="desktopImg"
      />
      <img
        className="absolute top-7 left-[18rem] h-5"
        src={bulbImg}
        alt="bulbImg"
      />
      <img
        className="absolute top-7 left-[21.3rem] h-5"
        src={elctronIcon}
        alt="electronIcon"
      />
      <img
        className="absolute top-[10rem] left-[13rem] h-7"
        src={bottleImg}
        alt="bottleImg"
      />
      <img
        className="absolute top-[10rem] left-[17.5rem] h-7"
        src={scaleN}
        alt="scaleN"
      />
      <img
        className="absolute top-[9rem] left-[21rem] h-5"
        src={nucleusImg}
        alt="nucleusImg"
      />
    </div>
  );
}

export default BackgroundImage;
