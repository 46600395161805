import Button from "./Button/Button";
import Card from "./Card/Card";
import heroImg from "../../assets/imgs/heroCompressed.png";
import searchPng from "../../assets/imgs/search.png";
import squarePng from "../../assets/imgs/square.png";
import gptPng from "../../assets/imgs/gpt.png";
import gridPng from "../../assets/imgs/grid.png";
import sampleResume1 from "../../assets/imgs/sample_resume1.png";
import sampleResume2 from "../../assets/imgs/sample_resume2.png";
import sampleResume3 from "../../assets/imgs/sample_resume3.png";
import pic1 from "../../assets/imgs/sandhya1.jpg";
import pic2 from "../../assets/imgs/nupurImg.jpeg";
import pic3 from "../../assets/imgs/rishikesh_copy.jpg";
import ReviewCard from "./Card/ReviewCard";

const ResumeService1 = () => {
  const backgroundStyle = {
    backgroundImage: `url(${heroImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "brightness(0.9)",
  };

  const handleClick = () => {
    window.open(
      "https://wa.me/7020999022?text=Hello%20sir%2Fmam%2C%20I%20want%20to%20order%20professional%20resume%20service",
      "_blank"
    );
  };

  return (
    <div className="overflow-x-hidden bg-gray-50">
      {/* Hero Section */}

      <section
        className="text-center py-12 bg-gray-50 lg:h-[500px]"
        style={backgroundStyle}
      >
        <div
          className="absolute inset-0 bg-black opacity-60"
          style={{ zIndex: -1 }}
        ></div>
        <h1 className="text-2xl font-bold mb-6 mt-20 lg:mt-40 text-white lg:text-[32px]">
          Boost Your Career with a Professional Resume!
        </h1>
        <Button label="Order Now" onClick={handleClick} />
      </section>

      <div className="p-2 border-x-4 border-t-4 border-gray-200">
        {/* Benefits Section */}

        <section className="py-6 px-3 text-customBlue">
          <h2 className="text-2xl font-bold text-center mb-4 lg:text-[32px] lg:mt-[20px]">
            Benefits
          </h2>
          <p className="text-center text-customBlue-600 mb-8 lg:text-[22px] lg:mt-[30px] lg:px-48">
            Professionally built resumes have a 60% higher chance of catching a
            recruiter's eye. Don't leave your next opportunity to chance-stand
            out with a customized, expertly crafted resume!
          </p>

          <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-8 lg:gap-y-8 lg:gap-x-0 justify-items-center px-2">
            <Card
              icon={squarePng}
              title="Customized Resume"
              description="A customized resume designed for your industry"
            />
            <Card
              icon={gptPng}
              title="AI Suggestions"
              description="AI-enhanced suggestions for optimal presentation"
            />
            <Card
              icon={gridPng}
              title="Proven Layouts"
              description="Proven layouts that attract recruiters"
            />
            <Card
              icon={searchPng}
              title="Expert Reviews"
              description="Resume reviewed by industry experts"
            />
          </div>
        </section>

        {/* Pricing Section */}
        <section className="text-center text-customBlue mt-4 lg:py-4">
          <h2 className="text-2xl lg:text-[32px] font-semibold mb-4 leading-none">
            Order Now
          </h2>
          <p className="mb-6 lg:text-[22px] leading-none">
            For only ₹2300 (including GST)
          </p>

          <section
            className="py-7 mb-6 px-4 lg:h-[70vh] h-[25vh] flex justify-center"
            style={backgroundStyle}
          >
            <div
              className="absolute inset-0 bg-black opacity-60"
              style={{ zIndex: -1 }}
            ></div>

            <div className="flex justify-center items-center content-center gap-1 px-6 ">
              {/* Left Image */}
              <img
                className="lg:h-[95%] h-40 object-cover"
                src={sampleResume1}
                alt="Resume Sample 1"
              />

              {/* Center Image - Larger */}
              <img
                className="lg:h-[100%] h-44 object-cover"
                src={sampleResume3}
                alt="Resume Sample 3"
              />

              {/* Right Image */}
              <img
                className="lg:h-[95%] h-40 object-cover"
                src={sampleResume2}
                alt="Resume Sample 2"
              />
            </div>
          </section>

          <Button
            width="w-72"
            label="Create My Resume"
            onClick={handleClick}
          />
        </section>

        {/* Reviews Section */}
        <div className="p-2 pb-10">
          <section className="py-6 px-1 text-customBlue">
            <h2 className="text-[24px] lg:pb-[35px] font-semibold text-center mb-6 lg:text-[32px] lg:mt-[20px]">
              Some feedbacks from our Clients about us
            </h2>

            <div className="flex gap-4 justify-center">
              <ReviewCard
                stars={5}
                imgSrc={pic1}
                name="Sandhya M"
                review="Overall, ResumeBuilder is a excellent tool for creating professional-looking resumes quickly and easily, making it a top choice."
              />
              <ReviewCard
                stars={4}
                imgSrc={pic2}
                name="Nupur P"
                review="I recently utilized Resume Builder to craft my professional resume, and my experience was overwhelmingly positive."
              />
              <ReviewCard
                stars={5}
                imgSrc={pic3}
                name="Rishikesh K"
                review="I found ResumeBuilder to be an exceptional tool for crafting a professional resume. The platform's user friendly interface is good"
              />
            </div>
          </section>
        </div>

      </div>
        <section className="py-4 lg:py-2 w-full lg:h-[50vh] flex flex-col items-center justify-center bg-[#F0F6FF] text-customBlue  leading-[30px] lg:leading-loose">
          <h2 className="text-[18px] mx-[6px] font-semibold text-center lg:text-[32px] pb-4">
            Boost your Career with Professional Resume at just{" "}
            <span className="text-fadedBlue line-through-diagonal">₹4,999</span>{" "}
            <br />
            <span className="text-blueLight">Now at ₹2300</span>{" "}
            <span className="text-[10px] font-normal">
              (Flat 54 % including GST)
            </span>
          </h2>

          <Button
            height="h-9"
            width="w-[119px] lg:w-[200px]"
            label="Order Now"
            labelSize="text-[13px]"
            onClick={handleClick}
          />
        </section>
    </div>
  );
};

export default ResumeService1;
