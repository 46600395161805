import arrowRight from "../../../assets/imgs/arrowRight.svg";
import crossIcon from "../../../assets/imgs/crossImg.svg";
import blueTick from "../../../assets/imgs/blueTick.svg";
import Button from "../../resume-services-v3/Button/Button";

interface PriceProps {
  heading?: string;
  originalPrice?: string;
  discount?: string;
  discountedPrice?: string;
  duration?: string;
  features?: string[];
  notIncluded?: string[];
  guarantee?: boolean;
}

function PriceCard({
  heading,
  originalPrice,
  discount,
  discountedPrice,
  duration,
  features = [],
  notIncluded = [],
  guarantee,
}: PriceProps) {
  return (
    <div className="card-width p-[9px] pl-[6px]  lg:pt-[20px] lg:w-[600px]  lg:p-[1rem] lg:pt-[63px] lg:pr-[2rem]">
      <div className="p-[6px]">
        <h3 className="text-[12px] font-semibold leading-[16px] m-0 lg:leading-[40px] lg:text-[28px]">
          {heading}
        </h3>
        <p className="card-sm-text pt-1 text-[#827E7E]">
          Price for 4 Months Course {guarantee && "+ " + duration}
        </p>
        <p className="card-sm-text text-[#404040] m-0 pr-0 leading-[7px] pt-2 lg:text-[22px] lg:leading-[30px] lg:pb-4">
          (Original Price <span className="line-through">{originalPrice}</span>)
        </p>
        <span className="text-[7px] text-lcsBlue lg:text-[18px] leading-[1px]">
          Flat {discount} Discount
        </span>

        {!guarantee && <div className="pt-[14px] lg:pt-[0px]"></div>}

        <h2 className="text-[20px] font-semibold pb-1 lg:text-[30px]">
          {discountedPrice}{" "}
        </h2>
        <Button
          height="h-6"
          width="w-full lg:w-full"
          label="Enroll Now"
          imageSrc={arrowRight}
          labelSize="text-[10px] lg:text-[20px]"
          onClick={() =>
            window.open(
              "https://wa.me/917020999022?text=Hello%20I%20would%20like%20to%20enroll%20myself%20for%20React%20Native%20course",
              "_blank"
            )
          }
        />

        <p className="card-sm-text lg:text-[1rem] font-semibold pt-2">
          Features
        </p>

        {features.map((e, index) => (
          <div
            key={index}
            className="flex items-center lg:gap-[15px] gap-[5px]"
          >
            <img
              className="h-[10px] lg:h-[20px]"
              src={blueTick}
              alt="tick_icon"
            />
            <p className="text-[8px] m-[2px] leading-[12px] text-black text-opacity-70 lg:text-[20px] lg:leading-[40px]">
              {e}
            </p>
          </div>
        ))}
        {notIncluded.map((e, index) => (
          <div
            key={index}
            className="flex items-center lg:gap-[15px] gap-[5px]"
          >
            <img
              className="h-[7px] lg:h-[20px] pl-[2px] relative top-[1.5px]"
              src={crossIcon}
              alt="cross_icon"
            />
            {index === 0 ? (
              <p className="text-[8px] text-left m-[2px] leading-[12px] text-black text-opacity-70 top-[2px] relative lg:text-[20px] lg:leading-[40px]">
                {e}
              </p>
            ) : (
              <p className="text-[8px] text-left m-[2px] mt-[3px] leading-[12px] text-black text-opacity-70 relative lg:text-[20px] lg:leading-[40px]">
                {e}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PriceCard;
