import medalImg from "../../assets/imgs/medalVector.svg";
import peopleImg from "../../assets/imgs/peopleVector.svg";
import lineImg from "../../assets/imgs/lineVector.svg";
import bookImg from "../../assets/imgs/bookVector.svg";
import ReasonCard from "./Cards/ReasonCard";

function WhyChooseUs() {
  return (
    <section className="py-4 bg-lightBlue mb-[40px] px-4 lg:py-[30px]">
      <h1 className="text-customBlue text-[24px] lg:text-[30px] font-semibold pt-2 pb-4 text-center">
        Why Choose us
      </h1>

      <div className="flex gap-[10px] justify-around lg:justify-around lg:pr-[1rem]">
        
        <div className="w-[140px] items-center flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img className="p-2 pl-[9px] pt-[7px] lg:w-[60px] lg:h-[60px]" src={medalImg} alt="" />
          </div>
          <ReasonCard
            width="lg:w-[250px]"
            heading="Proven Track Record"
            text="Taught by industry veterans with real world project experience"
          />
        </div>

        <div className="w-[120px] items-center flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img className="p-2 pt-[7px] lg:w-[60px] lg:h-[60px]" src={bookImg} alt="" />
          </div>
          <ReasonCard
            width="lg:w-[250px]"
            heading="Career-Focused Curriculum"
            text="Designed to make you job-ready"
          />
        </div>
        <div className="w-[120px] items-center flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img className="p-2 pt-[7px] lg:w-[60px] lg:h-[60px]" src={lineImg} alt="" />
          </div>
          <ReasonCard
            width="lg:w-[250px]"
            heading="Flexible Learning"
            text="Self-paced modules and live sessions for personal guidance"
          />
        </div>

        {/* <div className="w-[100px] flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img className="p-2 pt-[9px] lg:w-[60px] lg:h-[60px]" src={peopleImg} alt="" />
          </div>
          <ReasonCard
            width="w-[76px] lg:w-[250px]"
            heading="Stellar Community"
            text="Join a network of professionals and developers."
          />
        </div> */}
      </div>
    </section>
  );
}

export default WhyChooseUs;
