
interface ButtonProps {
  label: string;
  onClick?: () => void;
  width?: string;  
  height?: string;
  labelSize?: string 
}

const Button = ({ label, onClick, width = "w-72", height = "h-[48px]", labelSize = "text-lg" }: ButtonProps) => {
  return (
    <button
      className={`bg-blue-500 lg:h-[52px] lg:w-78 text-white px-6 rounded-lg hover:bg-blue-600 ${width} ${height}`}
      onClick={onClick}
    >
      <span className={`lg:text-[20px] ${labelSize}`}>{label}</span>
    </button>
  );
};

export default Button;
