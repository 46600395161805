interface ReasonProps {
  heading?: string;
  text?: string;
  width?: string;
}

function ReasonCard({ heading, text, width = "" }: ReasonProps) {
  return (
    <div className={`${width}`}>
      <h3 className="text-[10px] m-[0px] pt-[10px] font-medium leading-[11px] lg:text-[20px] lg:leading-[25px]">
        {heading}
      </h3>
      <p className="text-[10px] m-[0px] text-gray-600 leading-[13px] lg:text-[20px] lg:leading-[25px]">
        {text}
      </p>
    </div>
  );
}

export default ReasonCard;
