interface ButtonProps {
  label: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  labelSize?: string;
  imageSrc?: string;
}

const Button = ({
  label,
  onClick,
  width = "w-72",
  height = "h-[48px]",
  labelSize = "text-lg",
  imageSrc,
}: ButtonProps) => {
  return (
    <button
      className={`flex items-center justify-center gap-1 bg-blue-500 lg:h-[52px] lg:w-78 text-white px-6 rounded-lg hover:bg-blue-600 ${width} ${height}`}
      onClick={onClick}
    >
      <span className={`lg:text-[20px] ${labelSize}`}>{label}</span>
      {imageSrc && <img src={imageSrc} alt="icon" className="mr-2 w-5 h-5 " />}
    </button>
  );
};

export default Button;
