import Button from "./Button/Button";
import sampleResume1 from "../../assets/imgs/s_resume1.png";
import sampleResume2 from "../../assets/imgs/s_resume2.png";
import sampleResume3 from "../../assets/imgs/s_resume3.png";
import pic1 from "../../assets/imgs/ayushvsSqaure.jpeg";
import pic2 from "../../assets/imgs/arthavSqaureImg.jpeg";
import pic3 from "../../assets/imgs/arshNew.png";
import ReviewCard from "./Card/ReviewCard";
import stairsGirl from "../../assets/imgs/stairsGirl.svg";

const ResumeService3 = () => {
  const handleClick = () => {
    window.open(
      "https://wa.me/7020999022?text=Hello%20sir%2Fmam%2C%20I%20want%20to%20order%20professional%20resume%20service",
      "_blank"
    );
  };

  return (
    <div className="overflow-x-hidden">
      {/* Hero Section */}

      <section className="flex flex-col bg-[#F0F6FF95] h-[293px] lg:h-[90vh] overflow-y-hidden overflow-x-hidden  items-center relative">
        <div className="z-10 flex flex-col items-center">
          <h1 className="font-semibold text-customBlue text-[20px] lg:text-[40px] pt-16 px-16 text-center">
            Get your Resume built from an Expert
          </h1>
          <p className="text-customBlue text-[12px] lg:text-[20px]  px-[30px] text-center leading-[15px] lg:pb-[90px] pb-6">
            Professionally built resumes have a 60% higher chance of catching a
            recruiter’s eye.
          </p>
          <Button
            height="h-[30px]"
            width="w-[119px] lg:w-[200px]"
            label="Order Now"
            labelSize="text-[13px]"
            onClick={handleClick}
          />
        </div>

        <img
          src={sampleResume1}
          alt="Resume Example"
          className="border bottom-[-44px] lg:bottom-[-154px] absolute h-[160px] lg:h-[600px] -rotate-45 skew-x-26 skew-y-[33deg] object-cover bottom-0 left-0"
        />

        <img
          src={sampleResume2}
          alt="Resume Example"
          className="border bottom-[-44px] lg:bottom-[-154px] absolute h-[160px] lg:h-[600px] rotate-45 skew-x-26 skew-y-[-38deg] object-cover bottom-0 right-0"
        />
      </section>

      {/* 700 Clients */}

      <section className="grid grid-cols-2 text-center justify-items-center items-center justify-center py-12 lg:h-[80vh]">
        <div className="px-3 lg:pl-[100px]">
          <h1 className="text-customBlue text-[20px] lg:text-[30px] font-semibold py-8 text-left z-1">
            Join us over a Community of{" "}
            <span className="text-blueLight">700 Clients</span>
          </h1>
        </div>

        <div className="lg:h-[30rem] h-[10rem] pr-0 relative flex gap-1">
          <img
            className="lg:h-full h-full object-cover z-0 relative"
            src={sampleResume3}
            alt="screen_girl"
          />

          <img
            className="lg:h-full h-full object-cover z-0 relative"
            src={sampleResume1}
            alt="screen_girl"
          />
          <div className="absolute inset-y-0 left-0 w-full bg-gradient-to-r from-white to-transparent pointer-events-none"></div>
        </div>
      </section>

      {/* Running Girl */}

      <section className="grid grid-cols-2 text-center items-center lg:h-[70vh] px-3 pb-8">
        <div className="lg:h-[30rem] h-[10rem]">
          <img
            className="lg:h-full h-full object-cover"
            src={stairsGirl}
            alt="screen_girl"
          />
        </div>

        <div className="flex flex-col lg:pl-[150px] items-end lg:px-[100px]">
          <h1 className="text-customBlue text-[18px] lg:text-[30px] font-semibold py-8 text-end">
            Boost Your Career with Professional Resume!
          </h1>
          <Button
            height="h-[30px]"
            width="w-[119px] lg:w-[200px]"
            label="Order Now"
            labelSize="text-[13px]"
            onClick={handleClick}
          />
        </div>
      </section>

      {/* Reviews Section */}
      <div className="p-2">
        <section className="py-6 px-1 text-customBlue">
          <h2 className="text-[18px] px-6 lg:pb-[35px] font-semibold text-center mb-6 lg:text-[32px] lg:mt-[20px]">
            Some feedbacks from our Clients about us
          </h2>

          <div className="flex gap-6 justify-center items-stretch">
            <ReviewCard
              stars={5}
              imgSrc={pic1}
              name="Ayush S"
              review="Overall, ResumeBuilder is a excellent tool for creating professional-looking resumes quickly and easily, making it a top choice."
            />
            <ReviewCard
              stars={4}
              imgSrc={pic2}
              name="Atharva D"
              review="I recently utilized Resume Builder to craft my professional resume, and my experience was overwhelmingly positive."
            />
            <ReviewCard
              stars={5}
              imgSrc={pic3}
              name="Arshdeep S"
              review="I found ResumeBuilder to be an exceptional tool for crafting a professional resume. The platform's user friendly interface is good"
            />
          </div>
        </section>
      </div>

      <section className="py-6 px-1 lg:h-[50vh] flex flex-col items-center justify-center bg-[#F0F6FF] text-customBlue mt-12 leading-[30px] lg:leading-loose">
        <h2 className="text-[18px] mx-[6px] font-semibold text-center lg:text-[32px] lg:mt-[20px] pb-4">
          Boost your Career with Professional Resume at just{" "}
          <span className="text-fadedBlue line-through-diagonal">₹4,999</span>{" "}
          <br />
          <span className="text-blueLight">Now at ₹2300</span>{" "}
          <span className="text-[10px] font-normal">
            (Flat 54 % including GST)
          </span>
        </h2>

        <Button
          height="h-9"
          width="w-[119px] lg:w-[200px]"
          label="Order Now"
          labelSize="text-[13px]"
          onClick={handleClick}
        />
      </section>
    </div>
  );
};

export default ResumeService3;
