import xlogo from "../assets/imgs/xlogoBlue.png";
import iphonesImg from "../assets/imgs/iphonesImg.svg";

function IphoneOffer() {
  const shareLink =
    "https://wa.me/?text=%F0%9F%8C%9F%20I%E2%80%99m%20using%20Xampus%20%E2%80%93%20Your%20Go-To%20App%20for%20Jobs%20and%20Networking!%0A%0AI%E2%80%99ve%20started%20using%20Xampus%20to%20grow%20my%20professional%20network%20and%20find%20exciting%20opportunities.%20Let%E2%80%99s%20connect%20on%20Xampus!%0A%0A%F0%9F%93%B2%20Download%20now%3A%20https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.xampus.ai.app%0A%0AJoin%20me%20and%20thousands%20of%20others%20on%20Xampus%20to%20build%20your%20career%20and%20network%20like%20never%20before.%20%F0%9F%9A%80";

  return (
    <div className="overflow-x-hidden">
      <nav className="bg p-[1rem] flex flex-col items-center pb-[30px]">
        <img className="h-[40px] rounded" src={xlogo} alt="xampus_logo" />
      </nav>

      <section className="text-center ">
        <h1 className="lg:text-[40px] font-bold text-[18px] px-[3rem]">
          Share. Connect. Win the{" "}
          <span className=" font-bold bg-gradient-to-r from-pink-500 via-purple-500 to-red-500 text-transparent bg-clip-text">
            iPhone 16 Pro Max!{" "}
          </span>
        </h1>

        <h2 className="text-customBlue lg:text-[20px] lg:px-[14rem] pt-4 px-[10px]">
          The latest iPhone 16 Pro Max could be yours! All you need to do is
          share the Xampus app with 5 friends or groups
        </h2>
      </section>

      <section className="bg-black h-[90vh] mt-[3rem] text-center flex flex-col items-center text-white">
        <img className="w-[70%] pt-10" src={iphonesImg} alt="iphone_img" />

        <h2 className="lg:text-[20px] lg:px-[14rem] pt-10">
          Start sharing the Xampus App with atleast 5 friends or family members
          & ask them to download the App
        </h2>

        <button
          onClick={() => window.open(shareLink)}
          className="my-[4rem] w-[50%] h-[50px] bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:opacity-90"
        >
          Share Now
        </button>
      </section>
    </div>
  );
}

export default IphoneOffer;
