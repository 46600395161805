import { createContext, Dispatch, SetStateAction } from "react";

// Define the types for your form state
// interface SalaryRange {
//   min: string;
//   max: string;
// }

export interface PostDetailsForm {
  jobRole: string;
  jobDescription: string;
  companyName: string;
  officeAddress: string;
  jobType: string;
  jobModel: string;
  jobLocation: string;
  salaryRange: {
    min: string;
    max: string;
  };
  perks: string[];
  minimumEducation: string;
  experience: string;
  skills: string;
  languages: string[];
  otherRequirements: string;
  internshipDuration?: {
    startDate: string;
    endDate: string;
  }; // Updated to an object
}


// Define the context type
export interface PostContextType {
  postDetailsForm: PostDetailsForm;
  setPostDetailsForm: Dispatch<SetStateAction<PostDetailsForm>>;
}

// Create and export the context
export const PostContext = createContext<PostContextType | undefined>(undefined);
