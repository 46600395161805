import onlineLearning from "../../assets/imgs/onlineLearning.svg";

function CourseHighlights() {
  const highlights = [
    "Master the fundamentals of React Native with real-world applications",

    "Build stunning cross-platform mobile apps for Android and iOS",

    "Gain expertise in popular tools like Redux, and React Navigation",

    "Work on capstone projects to showcase your skills to clients",

    "Exclusive Internship hands-on experience in a professional environment",
  ];

  return (
    <div className="pl-4 bg-[#EAEAEA] relative lg:h-[60vh] lg:px-[8rem]">
      <div className="lg:pt-[2rem]">
        <h1 className="headings pt-3 pb-3 lg:pb-[2rem]">Course Highlights</h1>

        {/* Points and image Div */}
        <div className="flex">
          <ul className="list-disc flex flex-col pl-2 pr-[126px] gap-2 lg:gap-[2rem]">
            {/* Points */}
            {highlights.map((e, index) => (
              <li key={index} className="card-sm-text lg:text-[1rem]">
                {e}
              </li>
            ))}
          </ul>

          {/* Image */}

          <img
            className="absolute right-0 h-[8rem] lg:h-[20rem] lg:right-[15rem] lg:top-[2rem] "
            src={onlineLearning}
            alt="online_learning"
          />
        </div>

        <div className="flex justify-center align-center py-4 lg:py-[5rem]">
          <button
            onClick={() =>
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/Ads_%2FAdsPdfs%2FReact%20Native%20course.pdf?alt=media&token=c9082bae-7a2c-4d94-a891-13e8a32e3b1e",
                "_blank"
              )
            }
            className="bg-white rounded card-sm-text h-[25px] shadow-xl font-semibold w-[50%] lg:h-[40px] lg:text-[1rem]"
          >
            Download Syllabus
          </button>
        </div>
      </div>
    </div>
  );
}

export default CourseHighlights;
