import React, { useEffect, useState } from 'react'
import logo from '../../assets/imgs/xlogoBlue.png'
import { Link } from 'react-router-dom'

function HrNavbar() {
    const [lastScrollY, setLastScrollY] = useState(0)
    const [showNavbar, setShowNavbar] = useState<Boolean>(true)

    const handleScroll = () =>{
        if(window.scrollY > lastScrollY && window.scrollY > 100){
            setShowNavbar(false)
        }else{
            setShowNavbar(true)
        }
        setLastScrollY(window.scrollY)
    }

    useEffect(()=>{
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    },[lastScrollY])

  return (
    <nav className={`sticky top-0 left-0 w-full bg-[#24336B] transition-transform duration-300 ${showNavbar ? "transform translate-y-0" : "transform -translate-y-full"}`}>
      <div className='flex justify-between items-center px-6 md:px-12 py-5'>
        <Link to='/hr-portal/homepage'>
          <div className='left-side flex gap-x-2'>
              <img src={logo} alt="" className='w-[34px] h-[34px] rounded-md' />
              <span className='text-[21.94px] text-[#fff] font-semibold'>Xampus</span>
          </div>
        </Link>

        <div className='right-side flex gap-x-4 text-[#fff]'>
            {/* <span className='cursor-pointer'>Home</span> */}
            <span className='cursor-pointer text-[18px] hover:text-[#ffffff96]'>Settings</span>
        </div>
      </div>
    </nav>
  )
}

export default HrNavbar


