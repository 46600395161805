import { useState } from "react";
import expertImg from "../../assets/imgs/mentorImg.png";
import Button from "../resume-services-v3/Button/Button";
import linkedinIcon from "../../assets/imgs/linkedinIcon.png";

function MeetExpert() {
  const [view, setView] = useState(false);

  const handleClick = () => {
    setView(!view);
  };

  const mentorLinkedin =
    "https://www.linkedin.com/in/prashant-gadekar-a50a838a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";

  const info = [
    {
      title: "10+ Years of Experience:",
      para: "Extensive experience in the technology domain, specializing in mobile development.",
    },
    {
      title: "Industry Exposure:",
      para: "Worked with leading global firms, including Wipro, BayanPay, and other prominent product based companies",
    },
    {
      title: "International Collaboration:",
      para: "Collaborated with Dubai- and US-based firms, contributing to innovative projects in diverse markets.",
    },
    {
      title: "Mobile Development Expertise:",
      para: "Proficient in designing, developing, and optimizing mobile applications for various platforms.",
    },
    {
      title: "Product-Based Focus:",
      para: "Hands-on experience in building scalable and user-focused products for different industries",
    },
    {
      title: "Global Perspective:",
      para: "Skilled in addressing the unique challenges and requirements of international markets.",
    },
    {
      title: "Team Leadership: ",
      para: "Demonstrated ability to mentor and lead teams, ensuring high-quality deliverables",
    },
  ];

  return (
    <div className="flex flex-col items-center bg-[#E1F3FF] mt-[50px]">
      <h3 className="text-[24px] lg:text-[32px] lg:pb-[2rem] text-customBlue font-semibold m-0 pb-3 pt-5">
        Meet Our Expert
      </h3>

      <img
        className="w-[80px] h-[80px] lg:w-[140px] lg:h-[140px] bg-white p-2 rounded-md"
        src={expertImg}
        alt="expert_image"
      />

      <h4 className="text-customBlue text-[20px] lg:text-[24px] pt-3 lg:pt-[30px]">
        Prashant Gadekar
      </h4>
      <p className="text-[14px] lg:text-[20px] text-center text-black leading-[25px] px-[20px] text-opacity-75 pb-8 lg:pt-4 lg:mx-[20rem] lg:leading-[30px]">
        10+ Years of Experience in mobile development, working with top
        companies like Wipro and BayanPay.
      </p>

      {view && (
        <>
          <div className="flex gap-2 bg-white p-2 rounded-md mb-7">
            <img
              className="h-[24px] lg:h-[32px]"
              src={linkedinIcon}
              alt="linkedinIcon"
            />
            <button
              className="text-[14px] lg:text-[20px]"
              onClick={() => window.open(mentorLinkedin, "_blank")}
            >
              Visit linkedin
            </button>
          </div>

          <div className="flex flex-col self-start px-4 lg:gap-5 mb-8 lg:pl-[15rem] lg:pt-[5rem]">
            {info.map((e, index) => (
              <div className="" key={index}>
                <p className="text-customBlue text-[18px] leading-[18px] lg:text-[26px] leading-[28px]">
                  {e.title}
                </p>
                <p className="text-black text-[14px] lg:pt-3 text-opacity-70 leading-[18px] lg:text-[20px] leading-[28px]">
                  {e.para}
                </p>
              </div>
            ))}
          </div>
        </>
      )}

      <Button
        height="h-[40px]"
        width="w-[169px] lg:w-[200px]"
        label={view ? "Show Less" : "Know More"}
        labelSize="text-[16px] lg:text-[20px]"
        onClick={handleClick}
      />
      <div className="pb-[20px]"> </div>
    </div>
  );
}

export default MeetExpert;
