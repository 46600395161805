import Button from "../resume-services-v3/Button/Button";
import PriceCard from "./Cards/PriceCard";
import limitedTimeImg from "../../assets/imgs/limitedT.svg";
import calendarIcon from "../../assets/imgs/dateCalendar.svg";
import pic1 from "../../assets/imgs/sandhya1.jpg";
import pic2 from "../../assets/imgs/nupurImg.jpeg";
import pic3 from "../../assets/imgs/ayushvsSqaure.jpeg";
import MeetExpert from "./MeetExpert";
import FAQs from "./FAQs";
import Footer from "./Footer";
import WhyChooseUs from "./WhyChooseUs";
import CourseHighlights from "./CourseHighlights";
import BgSvgs from "./BgSvgs";
import ReviewCard from "./Cards/ReviewCard";

function RNService3() {
  return (
    <div className="overflow-x-hidden">
      <div className="w-full h-[58px] bg-customBlue fixed top-0 z-20 lg:h-[80px]">
        <p className="text-[14px] text-white text-center p-3 leading-[18px] m-0 lg:text-[18px] lg:leading-[2rem]">
          Course Start Date: January 10, 2025 <br /> Application Deadline:
          December 31, 2024
        </p>
      </div>

      <section className="flex items-center justify-between lg:justify-around py-5 px-2 lg:h-[90vh] mt-[50px]">
        {/* Content Section */}
        <div className="flex flex-col items-center text-center relative">
          <h2 className="headingsBlue text-center px-[5%] pt-[3rem]">
            Unleash Your Potential as a React Native Developer!{" "}
          </h2>

          <p className="subText px-[8%] pb-4 text-black text-opacity-80 lg:mb-[4rem]">
            Learn from Industry Experts and Skyrocket Your Career
          </p>

          <Button
            height="h-9"
            width="lg:w-[40%] w-[50%]"
            label="Enroll Now"
            labelSize="text-[16px] lg:text-[20px]"
            onClick={() =>
              window.open(
                "https://wa.me/917020999022?text=Hello%20I%20would%20like%20to%20enroll%20myself%20for%20React%20Native%20course",
                "_blank"
              )
            }
          />
        </div>

        <BgSvgs />
      </section>

      {/* PATH TO SUCCESS */}
      <section className="pb-16 lg:pt-[80px]">
        <h1 className="headingsBlue pt-3 pb-5 px-[10%] text-center lg:pb-[4rem]">
          Choose your path to success
        </h1>
        <div className="flex justify-evenly">
          <PriceCard
            heading="Standard React Native Mastery Course"
            originalPrice="₹1,20,000"
            discount="50%"
            discountedPrice="₹59,999"
            duration="4 months"
            features={[
              "2 Certificates",
              "Job placement assistance for 4 months",
              "Learn React Native from Basics to Advanced",
              "Expert Mentorship with 8+ Years of Experience",
              "Practical, Hands-On Projects",
              "Community Support and Networking ̥Opportunities",
            ]}
            notIncluded={[
              "4-Months Guaranteed Internship with Leading Companies",
              "Earn a Stipend of Up to ₹10,000/Month",
              "Interview Preparation",
            ]}
          />
          <PriceCard
            isRecommended={true}
            heading="React Native Mastery & Guaranteed Internship"
            originalPrice="₹1,60,000"
            discount="38.75%"
            discountedPrice="₹97,999"
            duration="4+4 month internship"
            features={[
              "3 Certificates",
              "Job placement assistance until you get placed",
              "Learn React Native from Basics to Advanced",
              "Expert Mentorship with 8+ Years of Experience",
              "Real-World Experience to Kickstart Your Career",
              "Community Support and Networking ̥Opportunities",
              "4-Months Guaranteed Internship with Leading Companies",
              "Earn a Stipend of Up to ₹10,000/Month",
              "Interview Preparation",
            ]}
          />
        </div>
      </section>

      <WhyChooseUs />
      <CourseHighlights />

      {/* LIMITED TIME OFFER */}

      <section className="mx-3 flex items-center lg:justify-around lg:h-[50vh] ">
        <div className="flex flex-col lg:gap-[1rem]">
          <h3 className="text-[20px] text-customBlue font-semibold leading-[24px] lg:text-[30px]">
            Limited-Time Offer! Don't Miss Out
          </h3>
          <p className="text-[12px] leading-[15px] pb-2 lg:text-[20px] lg:w-[700px] lg:leading-[2rem]">
            Enroll now to avail these massive discounts and secure your future
            in mobile app development!
          </p>
          <div className="flex align-center items-center gap-1 lg:gap-2">
            <img
              src={calendarIcon}
              alt="calendar_icon"
              className="w-[13px] h-[13px] lg:w-7 lg:h-7"
            />
            <p className="text-[12px] leading-loose m-0 text-customBlue lg:text-[24px] relative top-[1px]">
              Offer Valid Until - 31st Dec 2024
            </p>
          </div>
        </div>

        <div className=" w-[20rem]">
          <img
            className="h-full w-full"
            src={limitedTimeImg}
            alt="limited_time"
          />
        </div>
      </section>

      {/* STUDENT REVIEWS */}
      <div className="p-2  pt-[30px]">
        <section className="py-6 px-1 ">
          <h2 className="headingsBlue lg:pl-[5rem] text-center mb-6 lg:mt-[20px] lg:pb-[5rem]">
            What Students Say
          </h2>

          <div className="flex gap-4 justify-center">
            <ReviewCard
              stars={4}
              imgSrc={pic2}
              name="Nupur P"
              review="This course changed my career. The mentorship and internship were invaluable!"
            />
            <ReviewCard
              stars={5}
              imgSrc={pic1}
              name="Sandhya M"
              review="The projects and real-world exposure were exactly what I needed for my career"
            />
            <ReviewCard
              stars={5}
              imgSrc={pic3}
              name="Ayush S"
              review="This course changed my career The projects and real-world exposure"
            />
          </div>
        </section>
      </div>

      <MeetExpert />
      <FAQs />

      <Footer />
    </div>
  );
}

export default RNService3;
