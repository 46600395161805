import { useState } from "react";
import expertImg from "../../assets/imgs/mentorImg.png";
import linkedinIcon from "../../assets/imgs/linkedinIcon.png";
import rightArrow from "../../assets/imgs/rightArrow.svg";
import mentorImg from "../../assets/imgs/mentorPic.png";

function MeetExpert() {
  const [view, setView] = useState(false);

  const handleClick = () => {
    setView(!view);
  };

  const mentorLinkedin =
    "https://www.linkedin.com/in/prashant-gadekar-a50a838a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";

  const info = [
    {
      title: "10+ Years of Experience:",
      para: "Extensive experience in the technology domain, specializing in mobile development.",
    },
    {
      title: "Industry Exposure:",
      para: "Worked with leading global firms, including Wipro, BayanPay, and other prominent product based companies",
    },
    {
      title: "International Collaboration:",
      para: "Collaborated with Dubai- and US-based firms, contributing to innovative projects in diverse markets.",
    },
    {
      title: "Mobile Development Expertise:",
      para: "Proficient in designing, developing, and optimizing mobile applications for various platforms.",
    },
    {
      title: "Product-Based Focus:",
      para: "Hands-on experience in building scalable and user-focused products for different industries",
    },
    {
      title: "Global Perspective:",
      para: "Skilled in addressing the unique challenges and requirements of international markets.",
    },
    {
      title: "Team Leadership: ",
      para: "Demonstrated ability to mentor and lead teams, ensuring high-quality deliverables",
    },
  ];

  return (
    <div className="flex flex-col items-center bg-[#EAEAEA] mt-[25px]">
      <h3 className="text-[24px] lg:text-[32px] lg:pb-[2rem] font-semibold m-0 pb-3 pt-5">
        Expert
      </h3>

      <img
        className="w-[100px] h-[100px] lg:w-[140px] lg:h-[140px] rounded-full"
        src={mentorImg}
        alt="expert_image"
      />

      <h4 className="text-[20px] lg:text-[24px] pt-3 lg:pt-[30px]">
        Prashant Gadekar
      </h4>

      <div className="flex gap-2 bg-white px-3 py-1 rounded-md mt-4 mb-2">
        <img
          className="h-[24px] lg:h-[32px]"
          src={linkedinIcon}
          alt="linkedinIcon"
        />
        <button
          className="text-[14px] font-semibold lg:text-[20px] text-[#0077B7]"
          onClick={() => window.open(mentorLinkedin, "_blank")}
        >
          Linkedin
        </button>
      </div>

      <p className="text-[14px] lg:text-[20px] text-center text-black leading-[25px] px-[20px] text-opacity-75 pb-4 lg:pt-4 lg:mx-[20rem] lg:leading-[30px]">
        10+ Years of Experience in mobile development, working with top
        companies like Wipro and BayanPay.
      </p>

      <button
        onClick={handleClick}
        className="flex justify bg-white text-lcsBlue gap-3 justify-center items-center rounded px-3 py-1 shadow-2xl"
      >
        Know More{" "}
        <img
          className={`h-[20px] transition-transform ${
            view ? "rotate-90" : "rotate-0"
          }`}
          src={rightArrow}
          alt="right_arrow_icon"
        />
      </button>

      {view && (
        <>
          <ul className="flex list-disc flex-col self-start px-4 lg:gap-[2rem] mb-8 lg:pl-[15rem] lg:pt-[5rem] pt-6 mx-5 gap-4">
            {info.map((e, index) => (
              <li key={index} className="card-sm-text lg:text-[1rem]">
                <span className="font-semibold">{e.title}</span> {e.para}
              </li>
            ))}
          </ul>
        </>
      )}

      <div className="pb-[20px]"> </div>
    </div>
  );
}

export default MeetExpert;
