import { useState } from "react";
import arrowIcon from "../../assets/imgs/upArrow.svg";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. What is the eligibility for this course?",
      ans: "Anyone with basic programming knowledge can join. Prior experience in JavaScript is beneficial but not mandatory, as we will cover the basics.",
    },
    {
      question: "2. Is this course beginner-friendly?",
      ans: "Yes! The course is designed for beginners and intermediate learners. We start with the fundamentals and gradually progress to advanced topics.",
    },
    {
      question: "3. What will I learn in this course?",
      ans: `You will learn:
        Basics of React Native.
        Building cross-platform mobile applications.
        Debugging and deploying apps to iOS and Android stores.
        Real-world project development.`,
    },
    {
      question:
        "4. How does the guaranteed internship work in the premium plan?",
      ans: "Upon completing the 4-month course, you will be placed in a guaranteed 4-month internship with one of our partner companies. The internship will provide hands-on experience and help build your portfolio.",
    },
    {
      question:
        "5. Can I switch to the premium plan after enrolling in the basic plan?",
      ans: "Yes, you can upgrade to the premium plan anytime before December 31, 2024, by paying the difference in fees.",
    },
    {
      question: "6. Will I get a certificate after completing the course?",
      ans: "Yes, you will receive a globally recognized certificate upon successfully completing the course.",
    },
    {
      question: "7. What makes this course different from others?",
      ans: `Our course focuses on:
        Practical, project-based learning.
        Access to mentors with real-world experience.
        Guaranteed internship opportunities (premium plan).`,
    },
    {
      question: "8. What type of projects will I build?",
      ans: `You’ll work on real-world projects like:
        A social media app.
        A weather forecasting app.
        An e-commerce mobile app.
        These projects will be great additions to your portfolio.`,
    },
    {
      question: "9. Is there any support available during the course?",
      ans: "Yes, you’ll have access to dedicated support channels, including live chat, email support, and mentor sessions.",
    },
  ];

  return (
    <div className="px-3 ">
      <h1 className="headings font-semibold  pt-[70px] pb-[15px] lg:pb-[3rem] lg:text-center">
        <span className="block lg:hidden">FAQ's</span>
        <span className="hidden lg:block">
          Frequently Asked Questions (FAQs)
        </span>
      </h1>

      <div className="flex flex-col gap-[10px] lg:gap-[20px]">
        {faqs.map((e, index) => (
          <div key={index} className="w-full  max-w-[60rem] mx-auto">
            {/* FAQs item */}
            <div className="bg-[#EAEAEA] rounded">
              <div
                className="flex items-center justify-between pb-2 p-2 cursor-pointer"
                onClick={() => handleToggle(index)}
              >
                <span
                  className={`text-[15px] lg:text-[20px] ${
                    activeIndex === index
                      ? "text-black font-semibold"
                      : "text-black text-opacity-75"
                  }`}
                >
                  {e.question}
                </span>
                <img
                  src={arrowIcon}
                  alt="Arrow Icon"
                  className={`w-4 h-4 transition-transform bg-white rounded-full border-white border-4 ${
                    activeIndex === index ? "rotate-180" : "rotate-90"
                  }`}
                />
              </div>
              {activeIndex === index && (
                <div className="p-4 pt-0">
                  <p className="text-[14px] lg:text-[18px] lg:leading-[40px] text-[] leading-[25px] m-0">
                    {e.ans}
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
