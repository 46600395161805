import { useState } from "react";
import expertImg from "../../assets/imgs/mentorImg.png";
import Button from "../resume-services-v3/Button/Button";
import linkedinIcon from "../../assets/imgs/linkedinIcon.png";

function MeetExpert() {
  const [view, setView] = useState(false);

  const handleClick = () => {
    setView(!view);
  };

  const mentorLinkedin =
    "https://www.linkedin.com/in/prashant-gadekar-a50a838a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";

  const info = [
    {
      title: "10+ Years of Experience:",
      para: "Extensive experience in the technology domain, specializing in mobile development.",
    },
    {
      title: "Industry Exposure:",
      para: "Worked with leading global firms, including Wipro, BayanPay, and other prominent product based companies",
    },
    {
      title: "International Collaboration:",
      para: "Collaborated with Dubai- and US-based firms, contributing to innovative projects in diverse markets.",
    },
    {
      title: "Mobile Development Expertise:",
      para: "Proficient in designing, developing, and optimizing mobile applications for various platforms.",
    },
    {
      title: "Product-Based Focus:",
      para: "Hands-on experience in building scalable and user-focused products for different industries",
    },
    {
      title: "Global Perspective:",
      para: "Skilled in addressing the unique challenges and requirements of international markets.",
    },
    {
      title: "Team Leadership: ",
      para: "Demonstrated ability to mentor and lead teams, ensuring high-quality deliverables",
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <h3 className="headingsBlue lg:pb-[5rem] m-0 pb-[40px] pt-5 ">
        Meet Our Expert
      </h3>
      <div className="px-3">
        <div className="flex lg:items-center ">
          <img
            className="w-[140px] h-[140px] bg-white p-2 rounded-md lg:w-[200px] lg:h-[200px] lg:ml-[250px]"
            src={expertImg}
            alt="expert_image"
          />

          <div>
            <h4 className="px-[20px] text-[18px] font-semibold lg:text-[24px] pt-1 lg:pt-[30px]">
              Prashant Gadekar
            </h4>
            <p className="text-[12px] lg:text-[20px] text-black leading-[25px] px-[20px] text-opacity-75 pb-2 lg:pt-4 lg:leading-[30px] lg:mr-[14rem]">
              10+ Years of Experience in mobile development, working with top
              companies like Wipro and BayanPay.
            </p>
          </div>
        </div>

        {view && (
          <>
            <div className="flex justify-center pt-6">
              <div className="flex gap-2 bg-[#E1F3FF] w-[40%] p-2 rounded-xl mb-7 justify-center lg:w-[16%]">
                <img
                  className="h-[24px] lg:h-[32px]"
                  src={linkedinIcon}
                  alt="linkedinIcon"
                />
                <button
                  className="text-[14px] lg:text-[20px]"
                  onClick={() => window.open(mentorLinkedin, "_blank")}
                >
                  Visit linkedin
                </button>
              </div>
            </div>

            <div className="flex flex-col self-start px-4 lg:gap-5 mb-8 lg:pl-[15rem] lg:pt-[5rem]">
              {info.map((e, index) => (
                <div className="" key={index}>
                  <p className="text-customBlue text-[18px] leading-[18px] lg:text-[26px] leading-[28px]">
                    {e.title}
                  </p>
                  <p className="text-black text-[14px] lg:pt-3 text-opacity-70 leading-[18px] lg:text-[20px] leading-[28px]">
                    {e.para}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="px-[20px] pt-6 flex justify-center">
          <Button
            height="h-[40px]"
            width="w-[169px] lg:w-[200px]"
            label={view ? "Show Less" : "Know More"}
            labelSize="text-[16px] lg:text-[20px]"
            onClick={handleClick}
          />
        </div>
      </div>

      <div className="pb-[20px]"> </div>
    </div>
  );
}

export default MeetExpert;
