import React, { useState } from "react";
import blueLogo from "../../../assets/imgs/xlogoBlue.png";
import check from "../../../assets/imgs/green-check.svg";
import { useNavigate } from "react-router-dom";

function Payment() {
    const navigate = useNavigate()
  return (
    <div className="min-h-screen overflow-hidden">
      <div className="bg-white w-full grid grid-cols-1 md:grid-cols-[1.2fr_2fr]">
        <div className="relative left-box bg-[#24336B] hidden md:flex flex-col items-center">
          <div className="md:w-[75%]">
            <div className="flex min-h-full items-start my-[3rem]">
              <a href="/">
                <img
                  src={blueLogo}
                  className="h-[2rem] md:h-[3rem] rounded-md shadow-custom"
                  alt="Logo"
                />
              </a>
            </div>
            <div className="text-start flex items-center justify-center">
              <h2 className="font-semibold md:text-[32px] text-[#fff]">
                Job Posting Successfully completed !
              </h2>
            </div>
          </div>
        </div>

        <div className="right-box min-h-screen w-full flex flex-col justify-center items-center">
          <div className="flex flex-col items-center justify-center w-[80%] md:w-[50%] gap-y-6">
            <img src={check} alt="" className="h-1rem" loading="lazy" />
            <div>
            <h1 className="text-[14px] text-center md:text-[18px] text-[#24336B] font-semibold">Great Job! Your job has been posted.</h1>
            <p className="text-[14px] text-center md:text-[18px] text-[#000000c1] font-medium">You can view updates and applicants for this job posting on Home page.</p>
            </div>
            <button onClick={()=> navigate('/hr-portal/homepage')} className="w-full mt-5 bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[14px] text-white text-center py-4 rounded-md">
              Go to HomePage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
