import svg1 from "../../assets/imgs/bgSvg1.svg";
import svg2 from "../../assets/imgs/bgSvg2.svg";
import svg3 from "../../assets/imgs/bgSvg3.svg";
import svg4 from "../../assets/imgs/bgSvg4.svg";
import svg5 from "../../assets/imgs/bgSvg5.svg";

function BgSvgs() {
  return (
    <>
      <img
        className="absolute lg:h-[3rem] top-[25%] left-[0] lg:top-[50%]"
        src={svg1}
        alt="svg1"
      />
      <img
        className="absolute lg:h-[3rem] top-[10%] left-[30%] lg:top-[11%]"
        src={svg2}
        alt="svg2"
      />
      <img
        className="absolute lg:h-[3rem] top-[11%] left-[75%] lg:top-[14%]"
        src={svg3}
        alt="svg3"
      />
      <img
        className="absolute lg:h-[3rem] top-[35%] right-[10%] lg:top-[60%]"
        src={svg5}
        alt="svg4"
      />
      <img
        className="absolute lg:h-[3rem] top-[35%] left-[14%] lg:top-[75%]"
        src={svg4}
        alt="svg5"
      />
    </>
  );
}

export default BgSvgs;
