import Button from "../resume-services-v3/Button/Button";
import PriceCard from "./Cards/PriceCard";
import pic1 from "../../assets/imgs/sandhya1.jpg";
import pic2 from "../../assets/imgs/nupurImg.jpeg";
import pic3 from "../../assets/imgs/ayushvsSqaure.jpeg";
import MeetExpert from "./MeetExpert";
import FAQs from "./FAQs";
import Footer from "./Footer";
import WhyChooseUs from "./WhyChooseUs";
import CourseHighlights from "./CourseHighlights";
import devs from "../../assets/imgs/devs.svg";
import arrowRight from "../../assets/imgs/arrowRight.svg";
import LimitedTimeOffer from "./LimitedTimeOffer";
import ReviewCardBox from "./Cards/ReviewCardBox";
import BackgroundImage from "./BackgroundImage";

function RNService2() {
  return (
    <div className="overflow-x-hidden">
      {/* <div className="w-full h-[58px] bg-customBlue fixed top-0 z-20 lg:h-[80px]">
        <p className="text-[14px] text-white text-center p-3 leading-[18px] m-0 lg:text-[18px] lg:leading-[2rem]">
          Course Start Date: January 10, 2025 <br /> Application Deadline:
          December 31, 2024
        </p>
      </div> */}

      <section className="flex items-center justify-between lg:justify-around px-2 lg:h-[90vh] relative mt-5">
        {/* Content Section */}
        <div className="flex flex-col lg:pl-[70px] pl-2">
          <div className="flex flex-col gap-0 lg:gap-6 w-[210px] lg:w-[570px] z-10">
            <h1 className="headings pt-8 text-left">
              Unleash Your Potential as a React Native Developer!
            </h1>
            <p className="para text-left pb-2 leading-[20px] pr-[3px] text-lcsBlue">
              Learn from Industry Experts and Skyrocket Your Career
            </p>

            <Button
              height="h-9"
              width="w-[80%] lg:w-[40%]"
              label="Enroll Now"
              imageSrc={arrowRight}
              labelSize="text-[16px] lg:text-[20px]"
              onClick={() =>
                window.open(
                  "https://wa.me/917020999022?text=Hello%20I%20would%20like%20to%20enroll%20myself%20for%20React%20Native%20course",
                  "_blank"
                )
              }
            />
          </div>
        </div>

        {/* Image Section */}
        <div className="lg:h-[25rem] h-[6rem] pr-2 absolute lg:static right-0 top-12">
          <img
            className="lg:h-full h-full object-cover z-0 opacity-90"
            src={devs}
            alt="developers"
          />
        </div>

        {/* Backgound tiny images */}
        <BackgroundImage />
      </section>

      {/* PATH TO SUCCESS */}
      <section className="pb-4 mt-[2rem] lg:mt-[80px] bg-[#EAEAEA] rounded-[20px]">
        <h1 className="text-lcsBlue headings pt-2 pb-1 text-center lg:py-[2rem]">
          Choose your path to success
        </h1>
        <hr className="border-t border-white pb-4" />
        <div className="flex justify-evenly">
          <PriceCard
            heading="Standard React Native Mastery Course"
            originalPrice="₹1,20,000"
            discount="50%"
            discountedPrice="₹59,999"
            duration="4 months"
            features={[
              "2 Certificates",
              "Job placement assistance for 4 months",
              "Learn React Native from Basics to Advanced",
              "Expert Mentorship with 8+ Years of Experience",
              "Practical, Hands-On Projects",
              "Community Support and Networking Opportunities",
            ]}
            notIncluded={[
              "Interview Preparation",
              "4-Months Guaranteed Internship with Leading Companies",
              "Earn a Stipend of Up to ₹10,000/Month",
            ]}
          />
          <PriceCard
            heading="React Native Mastery & Guaranteed Internship"
            originalPrice="₹1,60,000"
            discount="38.75%"
            discountedPrice="₹97,999"
            duration="4-Month Guaranteed Internship"
            guarantee={true}
            features={[
              "3 Certificates",
              "Job placement assistance until you get placed",
              "Learn React Native from Basics to Advanced",
              "Expert Mentorship with 8+ Years of Experience",
              "Real-World Experience to Kickstart Your Career",
              "Community Support and Networking Opportunities",
              "Interview Preparation",
              "4-Months Guaranteed Internship with Leading Companies",
              "Earn a Stipend of Up to ₹10,000/Month",
            ]}
          />
        </div>
      </section>

      <WhyChooseUs />
      <CourseHighlights />

      <LimitedTimeOffer />

      <MeetExpert />
      <FAQs />

      <div className="p-2  pt-[30px]">
        <section className="py-6 px-1">
          <h2 className="headings text-lcsBlue lg:pb-[35px] lg:pl-[5rem] font-semibold text-center mb-6 lg:text-[32px] lg:mt-[20px]">
            Students who shared feedback
          </h2>

          <div className="flex gap-2 justify-center">
            <ReviewCardBox
              stars={4}
              imgSrc={pic2}
              name="Nupur P"
              review="This course changed my career. The mentorship and internship were invaluable!"
            />
            <ReviewCardBox
              stars={4}
              imgSrc={pic1}
              name="Sandhya M"
              review="The projects and real-world exposure were exactly what I needed for my career"
            />
            <ReviewCardBox
              stars={4}
              imgSrc={pic3}
              name="Ayush S"
              review="This course changed my career The projects and real-world exposure"
            />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default RNService2;
