// import chart from "../../assets/imgs/chart.svg";
// import laptop from "../../assets/imgs/laptop.svg";
// import carrerFolder from "../../assets/imgs/careerfolder.svg";
// import community from "../../assets/imgs/community.svg";
// import lines from "../../assets/imgs/linesR.svg";
import whyChooseUs from "../../assets/imgs/whyChooseUs.png";

function WhyChooseUs() {
  return (
    <section className="py-4 mb-[40px] px-4 lg:pb-[110px] lg:pt-[50px] flex flex-col items-center">
      <h1 className="headings text-lcsBlue  lg:text-[30px] font-semibold pt-2 pb-4 lg:pb-12 text-center">
        Why Choose Us?
      </h1>
      <img className="lg:h-[25rem]" src={whyChooseUs} alt="" />{" "}
      {/*  Image Size: 23kb */}
    </section>
  );
}

// ALIGNMENT ISSUE DUE TO DIFFERENT SCREEN SIZES BECAUSE OF LINES IMAGE
// function WhyChooseUs() {
//   return (
//     <section className="py-4 mb-[40px] px-4 lg:py-[30px]">
//       <h1 className="headings text-lcsBlue  lg:text-[30px] font-semibold pt-2 pb-4 text-center">
//         Why Choose Us?
//       </h1>

//       <div className="relative top-3">
//         <img
//           className="h-[9rem] absolute bottom-[-13px]  left-[135px]"
//           src={lines}
//           alt="linesImage"
//         />
//         <div className="flex gap-1 pr-[200px]">
//           <img src={chart} alt="chartImage" />
//           <p className="card-sm-text leading-[9px]">
//             Proven Track Record: Taught by industry veterans with real-world
//             project experience.
//           </p>
//         </div>

//         <div className="flex gap-1 pl-[200px]">
//           <img src={laptop} alt="laptopImage" />
//           <p className="card-sm-text leading-[9px]">
//             Flexible Learning: Self-paced modules and live sessions for personal
//             guidance.
//           </p>
//         </div>

//         <div className="flex gap-1 pr-[200px]">
//           <img src={carrerFolder} alt="careerFolderImage" />
//           <p className="card-sm-text leading-[9px]">
//             Career-Focused Curriculum: Designed to make you job-ready.
//           </p>
//         </div>

//         <div className="flex gap-1 pl-[200px]">
//           <img src={community} alt="communityImage" />
//           <p className="card-sm-text leading-[9px]">
//             Stellar Community: Join a network of professionals and developers.
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// }

export default WhyChooseUs;
