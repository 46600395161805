import Button from "../resume-services-v3/Button/Button";

function Footer() {
  return (
    <div className="flex flex-col items-center bg-[#E1F3FF] pb-[30px] mt-[30px] pt-[1rem] lg:pb-[15vh]">
      <h2 className="headingsBlue text-center px-4 lg:text-[30px] lg:pt-[2rem] lg:pb-[1rem]">
        Take the First Step Towards Your Dream Job
      </h2>
      <p className="text-[15px] text-[#0F205F] text-opacity-90 text-center px-4 pb-5 leading-[24px] lg:text-[18px] lg:pb-[3rem]">
        Your journey to becoming a top React Native developer starts here!
      </p>
      <Button
        height="h-[40px]"
        width="w-[169px] lg:w-[200px]"
        label="Enroll Now"
        labelSize="text-[16px] lg:text-[20px]"
        onClick={() =>
          window.open(
            "https://wa.me/917020999022?text=Hello%20I%20would%20like%20to%20enroll%20myself%20for%20React%20Native%20course",
            "_blank"
          )
        }
      />
    </div>
  );
}

export default Footer;
