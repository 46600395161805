import React, { useState, useEffect } from "react";
import blueLogo from "../../assets/imgs/xlogoBlue.png";
import { useAuth } from "../../contexts/firebaseContext/Context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AddPost() {
  const navigate = useNavigate();
  const { currentUser, addHrDetails } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    recruitingFor: "",
    companyName: "",
    employeeCount: "",
    heardAboutUs: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (!currentUser) {
      navigate("/auth/hr-portal");
    }
  }, [currentUser, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "heardAboutUs" && value === "Other") {
      setShowOtherInput(true);
    } else if (name === "heardAboutUs") {
      setShowOtherInput(false);
    }

    // Clear errors when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherInput(e.target.value);
    // Clear error for the other input
    setErrors((prevErrors) => ({
      ...prevErrors,
      otherInput: "",
    }));
  };
  
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
  
    const nameRegex = /^[A-Za-z\s]{4,16}$/; // Only letters and spaces, length 4 to 16
    const phoneNumberRegex = /^\d{10}$/; // Exactly 10 digits
  
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!nameRegex.test(formData.name)) {
      newErrors.name = "Name must be 4 to 16 characters long and contain only letters and spaces";
    }
  
    if (!formData.recruitingFor) {
      newErrors.recruitingFor = "Recruiting for is required";
    }
  
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
    }
  
    if (!formData.employeeCount) {
      newErrors.employeeCount = "Employee count is required";
    }
  
    if (!formData.heardAboutUs) {
      newErrors.heardAboutUs = "This field is required";
    } else if (showOtherInput && !otherInput.trim()) {
      newErrors.otherInput = "Please specify how you heard about us";
    }
  
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!phoneNumberRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be exactly 10 digits";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    const finalFormData = {
      ...formData,
      heardAboutUs: showOtherInput ? otherInput : formData.heardAboutUs,
    };

    setLoading(true);
    try {
      await addHrDetails(finalFormData);
      navigate('/hr-portal/add-post-details')
      toast.success("Post saved!");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen">
      <div className="bg-white w-full grid grid-cols-1 md:grid-cols-[1.1fr_2fr]">
        <div className="left-box bg-[#24336B] hidden md:flex flex-col items-center">
          <div className="md:w-[80%]">
            <div className="flex items-start mt-[3rem]">
              <a href="/">
                <img
                  src={blueLogo}
                  className="h-[2rem] md:h-[3rem] rounded-md shadow-custom"
                  alt="Logo"
                />
              </a>
            </div>
            <div className="mt-[6rem] text-start">
              <h2 className="font-semibold md:text-[32px] text-[#fff]">
                Xampus has people from diverse backgrounds
              </h2>
              <p className="font-medium md:text-[20px] text-[#ffffffc1]">
                This will help us in providing better experience for you on
                Xampus.
              </p>
            </div>
          </div>
        </div>
        <div className="right-box min-h-screen my-[4rem] w-full flex flex-col justify-center items-center space-y-8">
          <div className="text-center">
            <h2 className="text-[#0A1E67] text-[24.35px] font-semibold">
              Let's get going
            </h2>
            <p className="text-[#000b] text-[16px] font-semibold">
              We would need some info of your company
            </p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="mb-2 px-6 md:px-0 w-full md:w-[28rem]"
          >
            <div className="space-y-5 md:space-y-7">
              <div>
                <label
                  htmlFor="name"
                  className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="e.g. john milton"
                  className="input"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
              </div>
              <div>
                <label className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]">
                  Recruiting for
                </label>
                <div className="flex space-x-2 mt-1">
                  {[
                    "Own organisation",
                    "For Client/ Third Party",
                    "Individual",
                    "All",
                  ].map((option) => (
                    <button
                      type="button"
                      key={option}
                      className={`text-[11.91px] px-3 py-2 rounded-lg ${
                        formData.recruitingFor === option
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                      onClick={() =>
                        setFormData({ ...formData, recruitingFor: option })
                      }
                    >
                      {option}
                    </button>
                  ))}
                </div>
                {errors.recruitingFor && <p className="text-red-500 text-xs">{errors.recruitingFor}</p>}
              </div>
              <div>
                <label
                  htmlFor="companyName"
                  className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                >
                  Company name (Add NA incase of Individual)
                </label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="e.g. john milton"
                  className="input"
                />
                {errors.companyName && <p className="text-red-500 text-xs">{errors.companyName}</p>}
              </div>
              <div>
                <label
                  htmlFor="employeeCount"
                  className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                >
                  Number of employees in your company
                </label>
                <div className="flex flex-wrap space-x-2 mt-1">
                  {[
                    "1-10",
                    "11-50",
                    "51-200",
                    "201-500",
                    "501-1000",
                    "1001-5000",
                    "5001-10000",
                    "10001+",
                  ].map((option) => (
                    <button
                      type="button"
                      key={option}
                      className={`text-[11.91px] px-4 py-2 mt-2 rounded-lg ${
                        formData.employeeCount === option
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                      onClick={() =>
                        setFormData({ ...formData, employeeCount: option })
                      }
                    >
                      {option}
                    </button>
                  ))}
                </div>
                {errors.employeeCount && <p className="text-red-500 text-xs">{errors.employeeCount}</p>}
              </div>
              <div>
                <label
                  htmlFor="heardAboutUs"
                  className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                >
                  How you heard about us
                </label>
                <select
                  id="heardAboutUs"
                  name="heardAboutUs"
                  value={formData.heardAboutUs}
                  onChange={handleInputChange}
                  className="input"
                >
                  <option value="">Select an option</option>
                  <option
                    className="text-[12px] text-[#0A1E67] font-medium"
                    value="Company Website"
                  >
                    Company Website
                  </option>
                  <option
                    className="text-[12px] text-[#0A1E67] font-medium"
                    value="Search Engine"
                  >
                    Search Engine
                  </option>
                  <option
                    className="text-[12px] text-[#0A1E67] font-medium"
                    value="Social Media"
                  >
                    Social Media
                  </option>
                  <option
                    className="text-[12px] text-[#0A1E67] font-medium"
                    value="Online Advertisement"
                  >
                    Online Advertisement
                  </option>
                  <option
                    className="text-[12px] text-[#0A1E67] font-medium"
                    value="Referral from a Colleague or Friend"
                  >
                    Referral from a Colleague or Friend
                  </option>
                  <option
                    className="text-[12px] text-[#0A1E67] font-medium"
                    value="Other"
                  >
                    Other (Please Specify)
                  </option>
                </select>
                {errors.heardAboutUs && <p className="text-red-500 text-xs">{errors.heardAboutUs}</p>}
                {showOtherInput && (
                  <div className="mt-4">
                    <label
                      htmlFor="otherInput"
                      className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                    >
                      Please Specify
                    </label>
                    <input
                      type="text"
                      id="otherInput"
                      value={otherInput}
                      onChange={handleOtherInputChange}
                      className="input"
                      placeholder="Specify here"
                    />
                    {errors.otherInput && <p className="text-red-500 text-xs">{errors.otherInput}</p>}
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                >
                  Phone number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="e.g.77777 77777"
                  className="input"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                {errors.phoneNumber && <p className="text-red-500 text-xs">{errors.phoneNumber}</p>}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[14px] text-white text-center py-4 rounded-md disabled:opacity-50"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save & Post a Job"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddPost;
