import Button from "./Button/Button";
import sampleResume1 from "../../assets/imgs/s_resume1.png";
import sampleResume2 from "../../assets/imgs/s_resume2.png";
import sampleResume3 from "../../assets/imgs/s_resume3.png";
import manPng from "../../assets/imgs/manPng.png";
import pic1 from "../../assets/imgs/sandhya1.jpg";
import pic2 from "../../assets/imgs/nupurImg.jpeg";
import pic3 from "../../assets/imgs/rishikesh_copy.jpg";
import girlSvg from "../../assets/imgs/girlSvg.svg";
import ReviewCard from "./Card/ReviewCard";

const ResumeService2 = () => {
  const handleClick = () => {
    window.open("https://wa.me/7020999022?text=Hello%20sir%2Fmam%2C%20I%20want%20to%20order%20professional%20resume%20service", "_blank");
  }


  return (
    <div className="overflow-x-hidden">
      {/* Hero Section */}

      <section className="grid grid-cols-2 text-center justify-items-center items-center justify-center py-12 lg:h-[500px]">
        <div className="flex flex-col pl-3 lg:pl-[150px]">
          <h1 className="text-customBlue text-[20px] lg:text-[30px] font-semibold py-8 text-left ">
            Get your Resume built from an Expert
          </h1>
          <Button
            height="h-9"
            width="w-[119px] lg:w-[200px]"
            label="Order Now"
            labelSize="text-[13px]"
            onClick={handleClick}
          />
        </div>

        <div className="w-[200px] h-[200px] lg:h-[400px] lg:w-[400px] bg-[#F0F6FF] rounded-full">
          <img src={manPng} alt="laptop_man" />
        </div>

      </section>

      {/* Resume Section */}

      <section className="pt-20 mb-6 px-4 lg:h-[70vh] h-[30vh] flex justify-center bg-[#F0F6FF] overflow-hidden">
        <div className="flex justify-center items-center content-center px-6 relative">
          {/* Left Image */}
          <img
            className="lg:h-[95%] h-44 object-cover relative z-0"
            src={sampleResume1}
            alt="Resume Sample 1"
          />

          {/* Center Image - Larger */}
          <img
            className="lg:h-[100%] h-56 object-cover relative -mx-6 z-10 border"
            src={sampleResume2}
            alt="Resume Sample 2"
          />

          {/* Right Image */}
          <img
            className="lg:h-[95%] h-44 object-cover relative z-0"
            src={sampleResume3}
            alt="Resume Sample 3"
          />
        </div>
      </section>

      {/* End of Resume Section */}

      <section className="grid grid-cols-2 text-center justify-items-center items-center justify-center py-12 lg:h-[70vh]">
        <div className="flex flex-col pl-2 lg:pl-[150px]">
          <h1 className="text-customBlue text-[20px] lg:text-[30px] font-semibold py-8 text-left z-1">
            Boost Your Career with Professional Resume!
          </h1>
          <Button
            height="h-9"
            width="w-[119px] lg:w-[200px]"
            label="Order Now"
            labelSize="text-[13px]"
            onClick={handleClick}
          />
        </div>

        <div className="lg:h-full h-[10rem] pr-0 relative">
          <img
            className="lg:h-full h-full object-cover z-0 relative"
            src={girlSvg}
            alt="screen_girl"
          />
        </div>
      </section>

      {/* Reviews Section */}
      <div className="p-2">
        <section className="py-6 px-1 text-customBlue">
          <h2 className="text-[18px] px-6 lg:pb-[35px] font-semibold text-center mb-6 lg:text-[32px] lg:mt-[20px]">
            Some feedbacks from our Clients about us
          </h2>

          <div className="flex gap-4 justify-center">
            <ReviewCard
              stars={5}
              imgSrc={pic1}
              name="Sandhya M"
              review="Overall, ResumeBuilder is a excellent tool for creating professional-looking resumes quickly and easily, making it a top choice."
            />
            <ReviewCard
              stars={4}
              imgSrc={pic2}
              name="Nupur P"
              review="I recently utilized Resume Builder to craft my professional resume, and my experience was overwhelmingly positive."
            />
            <ReviewCard
              stars={5}
              imgSrc={pic3}
              name="Rishikesh K"
              review="I found ResumeBuilder to be an exceptional tool for crafting a professional resume. The platform's user friendly interface is good"
            />
          </div>
        </section>
      </div>

      <section className="py-6 px-1 lg:h-[50vh] flex flex-col items-center justify-center bg-[#F0F6FF] text-customBlue mt-12 leading-[30px] lg:leading-loose">
        <h2 className="text-[18px] mx-[6px] font-semibold text-center lg:text-[32px] lg:mt-[20px]">
          Join us over a Community <br /> of{" "}
          <span className="text-blueLight">700 Clients</span> and Boost your
          Career with Professional Resume at <br /> just{" "}
          <span className="text-fadedBlue line-through-diagonal">₹4,999</span>{" "}
          <span className="text-blueLight">Now at ₹2300</span>{" "}
          <span className="text-[10px] font-normal">
            (Flat 54 % including GST)
          </span>
        </h2>

        <p className="text-[12px] leading-loose text-center pb-4">
          Professionally built resumes have a 60% higher chance of catching a
          recruiter’s eye.{" "}
        </p>

        <Button
          height="h-9"
          width="w-[119px] lg:w-[200px]"
          label="Order Now"
          labelSize="text-[13px]"
          onClick={handleClick}
        />
      </section>
    </div>
  );
};

export default ResumeService2;
