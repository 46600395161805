import blueStar from "../../../assets/imgs/star_icon.svg";
import blurStar from "../../../assets/imgs/blurStar.svg";

interface CardProps {
  imgSrc?: string;
  name?: string;
  review?: string;
  stars?: number;
}

function ReviewCard({ imgSrc, name, review, stars = 0 }: CardProps) {
  const filledStars = Math.min(stars, 5);
  const totalStars = 5;
  return (
    <div className="flex flex-col justify-between items-center h-[120px] pt-1 text-slate-600 lg:pb-[400px]">
      <img className="h-8 rounded-full lg:h-40" src={imgSrc} alt="" />
      <p className="text-xs lg:text-[1.2rem] lg:py-3">{name}</p>
      {/* Add Stars here */}
      <div className="flex my-1">
        {Array.from({ length: totalStars }).map((_, index) => (
          <img
            key={index}
            src={index < filledStars ? blueStar : blurStar}
            alt={index < filledStars ? "Filled star" : "Empty star"}
            className="h-[10px] w-[10px] lg:h-10 lg:w-8"
          />
        ))}
      </div>

      <p className="text-[8px] lg:text-[1.2rem] mt-[6px] lg:leading-10 leading-3 text-center">
        {review}
      </p>
    </div>
  );
}

export default ReviewCard;
