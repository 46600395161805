import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/firebaseContext/Context';
import deletionImg from '../assets/imgs/deletionBg.svg';
import deletionSvg from '../assets/imgs/deletionSvg.svg';
import Navbar from '../components/Navbar';
import { toast } from 'react-toastify';

const AccountDeletion = () => {
  const navigate = useNavigate();
  const { currentUser, accountDeletion } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e:any) => {
    try {
      e.preventDefault();
      if (!inputValue) {
        setError('Email or phone number is required.');
      } else {
        setError('');
        accountDeletion(inputValue);
        // Handle form submission logic here
      }
      setInputValue('')
    } catch (error:any) {
      throw new Error
    }

  };

  const handleCancel = () => {
    navigate('/');
  };


  return (
    <>
    <Navbar styles='bg-[#f0ede4] border-b border-gray-300'/>
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center p-4" style={{ backgroundImage: `url(${deletionImg})` }}>
      <div className="bg-white shadow-lg rounded-lg max-w-6xl w-full grid grid-cols-1 md:grid-cols-2 p-8">
        <div className="flex items-center justify-center md:justify-start mb-8 md:mb-0">
          <img src={deletionSvg} alt="SVG Illustration" className="w-full max-w-md h-auto" />
        </div>
        <div className="p-4">
          <div className="items-center mb-6">
            <h1 className="text-2xl font-bold mb-4 md:mb-0 text-gray-800">Request for Account Deletion</h1>
          </div>
          <div className="mb-6 text-gray-700">
            <p className="mb-4">
              Send a request to delete your account and personally identifiable information (PII) that is stored on our system. You will receive an email to verify your request. Once the request is verified, we will take care of deleting your PII. If you just want to check what PII we have stored, you can request your data.
            </p>
            <h2 className="text-red-500 font-semibold">
              Note: Your request for account deletion will be fulfilled within three days.
            </h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="contact-info" className="block text-gray-700 mb-2">Email or Phone Number</label>
              <input
                type="text"
                id="contact-info"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="bg-gray-50 w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
              {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
            </div>
            <div className="flex gap-2 justify-end">
              <button type='submit' className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-600 transition duration-300">
                Send Request
              </button>

              <button onClick={handleCancel} className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-600 transition duration-300">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default AccountDeletion;
