import medalImg from "../../assets/imgs/medalVector.svg";
import confusedPeople from "../../assets/imgs/confusedImg.svg";
import lineImg from "../../assets/imgs/lineVector.svg";
import bookImg from "../../assets/imgs/bookVector.svg";
import peopleImg from "../../assets/imgs/peopleIcon.svg";
import ReasonCard from "./Cards/ReasonCard";

function WhyChooseUs() {
  return (
    <section className="bg-lightBlue flex lg:justify-around lg:h-[50vh] relative">
      <div className="pl-4 w-[150px] mr-5 lg:w-[30vw] lg:pl-[3rem]">
        <h2 className="headingsBlue py-[2rem]">Why choose us</h2>
        <img
          className="lg:absolute lg:bottom-0 lg:left-[3rem] lg:h-[70%]"
          src={confusedPeople}
          alt="confusedPeople"
        />
      </div>

      <div className="grid grid-rows-2 grid-cols-2 gap-[16px] pt-[1rem] mr-[1rem] lg:w-[70vw] lg:pt-[2rem] lg:pl-[2rem]">
        <div className="w-[100px] h-20 flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img
              className="p-2 pl-[9px] pt-[7px] lg:w-[60px] lg:h-[60px]"
              src={medalImg}
              alt=""
            />
          </div>
          <ReasonCard
            width="lg:w-[350px]"
            heading="Proven Track Record"
            text="Taught by industry veterans with real world project experience"
          />
        </div>

        <div className="w-[100px] h-20 flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img
              className="p-2 pt-[7px] lg:w-[60px] lg:h-[60px]"
              src={bookImg}
              alt=""
            />
          </div>
          <ReasonCard
            width="lg:w-[350px]"
            heading="Career-Focused Curriculum"
            text="Designed to make you job-ready"
          />
        </div>

        <div className="w-[100px] h-20 flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded">
            <img
              className="p-2 pt-[7px] lg:w-[60px] lg:h-[60px]"
              src={lineImg}
              alt=""
            />
          </div>
          <ReasonCard
            width="lg:w-[350px]"
            heading="Flexible Learning"
            text="Self-paced modules and live sessions for personal guidance"
          />
        </div>

        <div className="w-[100px] h-20 flex flex-col gap-[0px]">
          <div className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] bg-white rounded pb-[2px]">
            <img
              className="p-2 pt-[7px] lg:w-[60px] lg:h-[60px]"
              src={peopleImg}
              alt=""
            />
          </div>
          <ReasonCard
            width="lg:w-[350px]"
            heading="Stellar Community"
            text="Join a network of professionals and developers"
          />
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
