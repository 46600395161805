// Card.tsx
import React from "react";

interface CardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ icon, title, description }) => {
  return (
    <div className="bg-blue-50 rounded-lg flex flex-col justify-center items-center
      w-[165px] h-[167px] 
      sm:w-[200px] sm:h-[220px] 
      md:w-[95%] lg:h-[280px] 
      lg:w-[95%] lg:h-[280px]
      lg:shadow">
      {/* Image */}
      <img src={`${icon}`} alt={title} className="mb-0 object-contain
      w-[32px] h-[32px] 
        sm:w-[48px] sm:h-[48px] 
        md:w-[64px] md:h-[64px] 
        lg:w-[80px] lg:h-[80px]"
       />
      {/* Description */}
      <p className="
      text-[14px] 
      lg:text-[20px] 
      lg:py-7
      md:text-[18px] 
      lg:leading-loose 
      text-customBlue-600 
      text-center
      leading-tight py-2 px-4">
        {description}</p>
    </div>
  );
};

export default Card;