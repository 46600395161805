import React, { useEffect, useState } from "react";
import frame_2 from "../../assets/imgs/Frame2.svg";
import frame_1 from "../../assets/imgs/Frame1.svg";
import HrNavbar from "../../components/hr-navbar/HrNavbar";
import JobsSection from "../../components/hr-portal/homepage-jobs/JobsSection";
import { useAuth } from "../../contexts/firebaseContext/Context";
import { useNavigate } from "react-router-dom";

function HrHomepage() {
  const navigate = useNavigate()
  const [postedJobs, setPostedJobs] = useState<any[]>([])
  const {currentUser, fetchJobsData, authLoading} = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      if (!authLoading && currentUser) {
        try {
          const res = await fetchJobsData(currentUser.uid);
          setPostedJobs(res); // Assuming `fetchJobsData` returns the fetched jobs
          console.log("user", res);
        } catch (error) {
          console.error("Error fetching jobs:", error);
        }
      }
    };
    
    fetchData(); // Call the fetch function
  }, [currentUser, authLoading, fetchJobsData]);

  return (
    <>
      <HrNavbar />
      <section className="min-h-screen grid grid-cols-1 md:grid-cols-[1.5fr_2fr] pt-14 md:mt-6 w-full">
        <div
          id="left-section"
          className="hidden md:flex flex-col items-center gap-4"
        >
          <div
            id="posted-jobs"
            className="w-[377px] h-[121px] rounded-[19.16px] shadow-spread grid items-center px-6 cursor-pointer hover:scale-105"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center gap-3">
                <img src={frame_1} alt="" />
                <span className="text-[24px] text-[#24336B] font-medium">
                  Posted Jobs
                </span>
              </div>
              <span className="text-[24px] text-[#24336B]">{postedJobs.length}</span>
            </div>
          </div>

          <div
            id="post-new-job"
            onClick={()=> navigate('/hr-portal/add-post-details')}
            className="w-[377px] h-[121px] rounded-[19.16px] shadow-spread grid items-center px-6 cursor-pointer hover:scale-105"
          >
            <div className="text-[24px] text-[#24336B] flex items-center justify-between">
              <div className="flex items-center justify-center gap-3">
                <img src={frame_2} alt="" />
                <span className="text-[24px] text-[#24336B] font-medium">
                  Post a new Job
                </span>
              </div>
              <span className="text-[28px] text-[#24336B] font-medium">+</span>
            </div>
          </div>
        </div>

        <div id="right-section">
          <JobsSection postedJobs={postedJobs}/>
        </div>
      </section>
    </>
  );
}

export default HrHomepage;
