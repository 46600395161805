import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PostContext } from "../../../contexts/hr-portal-context/Context";
import {LANGUAGES} from '../../../utils/Utils'

interface Props {
  formIndex: () => void;
}

function AddApplicantQualification({ formIndex }: Props) {
  const context = useContext(PostContext);
  if (!context) {
    throw new Error("AddJobDetails must be used within a PostContext.Provider");
  }

  const { postDetailsForm, setPostDetailsForm } = context;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [addApplicantDetails, setAddApplicantDetails] = useState({
    minimumEducation: "",
    experience: "",
    skills: "",
    languages: [] as string[],
    otherRequirements: "",
  });
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const validate = () => {
    let newErrors: any = {};

    if (!addApplicantDetails.minimumEducation) {
      newErrors.minimumEducation = "Education is required";
    }
    if (!addApplicantDetails.experience) {
      newErrors.experience = "Experience is required";
    }
    if (!addApplicantDetails.skills) {
      newErrors.skills = "Skills are required";
    }
    if (!addApplicantDetails.languages.length) {
      newErrors.languages = "At least one language is required";
    }
    if (!addApplicantDetails.otherRequirements) {
      newErrors.otherRequirements = "Requirements are required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });
    setAddApplicantDetails({
      ...addApplicantDetails,
      [name]: value,
    });
  };

  const handleLanguageAddition = () => {
    if (selectedLanguage && !addApplicantDetails.languages.includes(selectedLanguage)) {
      setAddApplicantDetails({
        ...addApplicantDetails,
        languages: [...addApplicantDetails.languages, selectedLanguage],
      });
      setSelectedLanguage("");
    }
  };

  const handleLanguageRemoval = (language: string) => {
    setAddApplicantDetails({
      ...addApplicantDetails,
      languages: addApplicantDetails.languages.filter(
        (lang) => lang !== language
      ),
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    try {
      setPostDetailsForm((prevForm) => ({
        ...prevForm,
        ...addApplicantDetails,
      }));
      formIndex();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAddApplicantDetails({
      ...postDetailsForm
    });
  }, [postDetailsForm]);

  return (
    <main className="bg-[#fff] flex items-center justify-center w-full my-4 rounded-xl">
      <div className="flex flex-col w-[95%] md:w-[70%] p-6 md:p-8 lg:p-16 gap-y-8 md:gap-y-10 lg:gap-y-12">
        <form
          onSubmit={handleSubmit}
          className="space-y-8 md:space-y-10 lg:space-y-12"
        >
          <div>
            <label htmlFor="education" className="hr-portal-label">
              Minimum education*
            </label>
            <div className="flex flex-wrap space-x-2 mt-1">
              {["10th", "12th", "Graduate", "Diploma", "Post-Graduate"].map(
                (option) => (
                  <button
                    type="button"
                    key={option}
                    className={`text-[11px] font-medium px-6 py-4 mt-2 rounded-xl ${
                      addApplicantDetails.minimumEducation === option
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-[#0008]"
                    }`}
                    onClick={() =>
                      setAddApplicantDetails({
                        ...addApplicantDetails,
                        minimumEducation: option,
                      })
                    }
                  >
                    {option}
                  </button>
                )
              )}
            </div>
            {errors.minimumEducation && (
              <p className="text-red-500 text-sm">{errors.minimumEducation}</p>
            )}
          </div>

          <div>
            <label htmlFor="experience" className="hr-portal-label">
              Experience (in years)*
            </label>
            <div className="flex flex-wrap space-x-2 mt-1">
              {["0-2", "3-4", "5-6", "7-10", "10+"].map((option) => (
                <button
                  type="button"
                  key={option}
                  className={`text-[11px] font-medium px-6 py-4 mt-2 rounded-xl ${
                    addApplicantDetails.experience === option
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-[#0008]"
                  }`}
                  onClick={() =>
                    setAddApplicantDetails({
                      ...addApplicantDetails,
                      experience: option,
                    })
                  }
                >
                  {option}
                </button>
              ))}
            </div>
            {errors.experience && (
              <p className="text-red-500 text-sm">{errors.experience}</p>
            )}
          </div>

          <div>
            <label htmlFor="skills" className="hr-portal-label">
              Skills*
            </label>
            <input
              type="text"
              name="skills"
              value={addApplicantDetails.skills}
              onChange={handleInputChange}
              className="post-details-input"
              placeholder="e.g., Adobe Creative Suite"
            />
            {errors.skills && (
              <p className="text-red-500 text-sm">{errors.skills}</p>
            )}
          </div>

          <div>
            <label htmlFor="languages" className="hr-portal-label">
              Languages*
            </label>
            <div className="flex flex-wrap gap-2 mt-2 items-center">
              {addApplicantDetails.languages.map((language) => (
                <span
                  key={language}
                  className="flex items-center bg-gray-200 text-[11px] text-[#0008] px-4 py-2 rounded-xl"
                >
                  {language}
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handleLanguageRemoval(language)}
                  >
                    &times;
                  </button>
                </span>
              ))}
              <select
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                className="placeholder:text-[#00000095] placeholder:text-[10.63px] md:text-[10.63px] leading-[24px] border border-[#333131] py-3 px-1 md:px-3 md:py-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-700 transition duration-300 mt-2 md:mt-4"
              >
                <option value="">Select a language</option>
                {LANGUAGES.map((language) => (
                  <option key={language} value={language}>
                    {language}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={handleLanguageAddition}
                className="bg-blue-500 mt-3 text-white text-[1.5rem] font-medium flex items-center justify-center rounded-full h-7 w-7"
              >
                +
              </button>
            </div>
            {errors.languages && (
              <p className="text-red-500 text-sm">{errors.languages}</p>
            )}
          </div>

          <div>
            <label htmlFor="requirements" className="hr-portal-label">
              Other Requirements*
            </label>
            <input
              type="text"
              name="otherRequirements"
              value={addApplicantDetails.otherRequirements}
              onChange={handleInputChange}
              className="post-details-input"
              placeholder="e.g., Women candidates only"
            />
            {errors.otherRequirements && (
              <p className="text-red-500 text-sm">{errors.otherRequirements}</p>
            )}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[14px] text-white text-center py-4 md:py-6 rounded-md disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save & Continue"}
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

export default AddApplicantQualification;
