import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PostContext } from "../../../contexts/hr-portal-context/Context";

interface Props {
  formIndex: () => void;
}

function AddJobDetails({ formIndex }: Props) {
  const context = useContext(PostContext);

  if (!context) {
    throw new Error("AddJobDetails must be used within a PostContext.Provider");
  }

  const { postDetailsForm, setPostDetailsForm } = context;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [newPerk, setNewPerk] = useState<string>("");
  const [addJobDetails, setAddJobDetails] = useState({
    jobRole: "",
    jobDescription: "",
    companyName: "",
    officeAddress: "",
    jobType: "",
    jobModel: "",
    jobLocation: "",
    salaryRange: {
      min: "",
      max: "",
    },
    internshipDuration: {
      startDate: "",
      endDate: "",
    },
    perks: [] as string[],
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "startDate" || name === "endDate") {
      setAddJobDetails((prevState) => ({
        ...prevState,
        internshipDuration: {
          ...prevState.internshipDuration,
          [name]: value,
        },
      }));
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
      setAddJobDetails({
        ...addJobDetails,
        [name]: value,
      });
    }
  };

  const handlePerkAddition = () => {
    if (newPerk && !addJobDetails.perks.includes(newPerk)) {
      setAddJobDetails((prevState) => ({
        ...prevState,
        perks: [...prevState.perks, newPerk],
      }));
      setNewPerk("");
    } else {
      setErrors({ ...errors, perks: "This perk is already added or empty!" });
    }
  };

  const handlePerkRemoval = (perkToRemove: string) => {
    setAddJobDetails((prevState) => ({
      ...prevState,
      perks: prevState.perks.filter((perk) => perk !== perkToRemove),
    }));
  };

  const validate = () => {
    let newErrors: Record<string, string> = {};

    if (!addJobDetails.jobRole) {
      newErrors.jobRole = "Job role is required";
    }
    if (!addJobDetails.jobDescription) {
      newErrors.jobDescription = "Job description is required";
    }
    if (!addJobDetails.companyName) {
      newErrors.companyName = "Company name is required";
    }
    if (!addJobDetails.officeAddress) {
      newErrors.officeAddress = "Office address is required";
    }
    if (!addJobDetails.jobType) {
      newErrors.jobType = "Job type is required";
    }
    if (!addJobDetails.jobModel) {
      newErrors.jobModel = "Job model is required";
    }
    if (!addJobDetails.jobLocation) {
      newErrors.jobLocation = "Job location is required";
    }
    if (!addJobDetails.salaryRange.min || !addJobDetails.salaryRange.max) {
      newErrors.salaryRange = "Salary range is required";
    }

    // Validate internship duration if job type is "Internship"
    if (addJobDetails.jobType === "Internship") {
      if (
        !addJobDetails.internshipDuration.startDate ||
        !addJobDetails.internshipDuration.endDate
      ) {
        newErrors.internshipDuration = "Internship duration is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      // Update the postDetailsForm in the context with the current state
      setPostDetailsForm((prevState) => ({
        ...prevState,
        ...addJobDetails, // Spread the current state to update with form data
      }));

      toast.success("Job details saved successfully.");
      formIndex();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setAddJobDetails((prevState) => ({
      ...prevState,
      ...postDetailsForm, // Spread postDetailsForm and preserve other state properties
    }));
  }, [postDetailsForm]);
  return (
    <main className="bg-[#fff] flex items-center justify-center w-full my-4 rounded-xl">
      <div className="flex flex-col w-[95%] md:w-[88%] lg:w-[70%] p-6 md:p-8 lg:p-16 gap-y-8 md:gap-y-10 lg:gap-y-12">
        <form
          onSubmit={handleSubmit}
          className="space-y-8 md:space-y-10 lg:space-y-12"
        >
          {/* Job Role */}
          <div>
            <label htmlFor="jobRole" className="hr-portal-label">
              Job role/title*
            </label>
            <input
              type="text"
              name="jobRole"
              value={addJobDetails.jobRole}
              onChange={handleInputChange}
              className="post-details-input"
              placeholder="e.g. Graphics designer"
            />
            {errors.jobRole && (
              <p className="text-red-500 text-sm">{errors.jobRole}</p>
            )}
          </div>

          {/* Job Description */}
          <div>
            <label htmlFor="jobDescription" className="hr-portal-label">
              Job description*
            </label>
            <textarea
              name="jobDescription"
              value={addJobDetails.jobDescription}
              onChange={handleInputChange}
              className="post-details-input no-scrollbar"
              rows={4}
              placeholder="e.g. At Xampus we are looking for a talented graphics designer..."
            ></textarea>
            {errors.jobDescription && (
              <p className="text-red-500 text-sm">{errors.jobDescription}</p>
            )}
          </div>

          {/* Company Name */}
          <div>
            <label htmlFor="companyName" className="hr-portal-label">
              Company name*
            </label>
            <input
              type="text"
              name="companyName"
              value={addJobDetails.companyName}
              onChange={handleInputChange}
              className="post-details-input"
              placeholder="e.g. Xampus"
            />
            {errors.companyName && (
              <p className="text-red-500 text-sm">{errors.companyName}</p>
            )}
          </div>

          {/* Office Address */}
          <div>
            <label htmlFor="officeAddress" className="hr-portal-label">
              Office address*
            </label>
            <input
              type="text"
              name="officeAddress"
              value={addJobDetails.officeAddress}
              onChange={handleInputChange}
              className="post-details-input"
              placeholder="e.g. Sector 4, near airport, Pune"
            />
            {errors.officeAddress && (
              <p className="text-red-500 text-sm">{errors.officeAddress}</p>
            )}
          </div>

          {/* Job Type */}
          <div>
            <label htmlFor="jobType" className="hr-portal-label">
              Job type*
            </label>
            <div className="flex flex-wrap space-x-2 mt-1">
              {["Full-time", "Part-time", "Internship", "Contract"].map(
                (option) => (
                  <button
                    type="button"
                    key={option}
                    className={`text-[11px] font-medium px-6 py-4 mt-2 rounded-xl ${
                      addJobDetails.jobType === option
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-[#0008]"
                    }`}
                    onClick={() =>
                      setAddJobDetails({ ...addJobDetails, jobType: option })
                    }
                  >
                    {option}
                  </button>
                )
              )}
            </div>
            {errors.jobType && (
              <p className="text-red-500 text-sm">{errors.jobType}</p>
            )}
          </div>

          {/* Internship Duration (conditional rendering) */}
          {addJobDetails.jobType === "Internship" && (
            <div>
              <label className="hr-portal-label">Internship Duration*</label>
              <div className="flex space-x-4 items-center">
                <input
                  type="date"
                  name="startDate"
                  value={addJobDetails.internshipDuration.startDate}
                  onChange={handleInputChange}
                  className="post-details-input"
                />
                <p className="text-[#24336B] text-[12.4px] font-medium">to</p>
                <input
                  type="date"
                  name="endDate"
                  value={addJobDetails.internshipDuration.endDate}
                  onChange={handleInputChange}
                  className="post-details-input"
                />
              </div>
              {errors.internshipDuration && (
                <p className="text-red-500 text-sm">
                  {errors.internshipDuration}
                </p>
              )}
            </div>
          )}

         {/* Job Model */}
         <div>
            <label htmlFor="job model" className="hr-portal-label">
              Job model*
            </label>
            <div className="flex flex-wrap space-x-2 mt-1">
              {["Remote", "Hybrid", "On-site"].map((option) => (
                <button
                  type="button"
                  key={option}
                  className={`text-[11px] font-medium px-6 py-4 mt-2 rounded-xl ${
                    addJobDetails.jobModel === option
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-[#0008]"
                  }`}
                  onClick={() =>
                    setAddJobDetails({ ...addJobDetails, jobModel: option })
                  }
                >
                  {option}
                </button>
              ))}
            </div>
            {errors.jobModel && (
              <p className="text-red-500 text-sm">{errors.jobModel}</p>
            )}
          </div>

          {/* Job Location */}
          <div>
            <label htmlFor="job location" className="hr-portal-label">
              Job location*
            </label>
            <input
              type="text"
              name="jobLocation"
              value={addJobDetails.jobLocation}
              onChange={handleInputChange}
              className="post-details-input"
              placeholder="e. g. new town,koregaon park"
            />
            {errors.jobLocation && (
              <p className="text-red-500 text-sm">{errors.jobLocation}</p>
            )}
          </div>

          {/* Salary Rnage */}
          <div>
            <label htmlFor="salary" className="hr-portal-label">
              Salary range (in LPA)*
            </label>
            <div className="mt-4">
              <span className="text-[11px] text-[#0008]text-[#0008]">
                <input
                  type="number"
                  name="min"
                  value={addJobDetails.salaryRange.min}
                  onChange={(e) =>
                    setAddJobDetails({
                      ...addJobDetails,
                      salaryRange: {
                        ...addJobDetails.salaryRange,
                        min: e.target.value,
                      },
                    })
                  }
                  className="placeholder:text-[#0008] bg-[#E6E6E6] px-2 py-4 rounded-xl w-[125px] border-none outline-none text-[11px] text-[#0008]"
                  placeholder="min pay"
                />
                (LPA) -{" "}
                <input
                  type="number"
                  name="salaryRangeMax"
                  value={addJobDetails.salaryRange.max}
                  onChange={(e) =>
                    setAddJobDetails({
                      ...addJobDetails,
                      salaryRange: {
                        ...addJobDetails.salaryRange,
                        max: e.target.value,
                      },
                    })
                  }
                  className="placeholder:text-[#0008] bg-[#E6E6E6] px-2 py-4 rounded-xl w-[125px] border-none outline-none text-[11px] text-[#0008]"
                  placeholder="max pay"
                />
                (LPA)
              </span>
            </div>
            {errors.salaryRange && (
              <p className="text-red-500 text-sm">{errors.salaryRange}</p>
            )}
          </div>

          {/* Perks */}
          <div>
            <label className="hr-portal-label">Perks</label>
            <div className="flex flex-wrap gap-2 mt-2">
              {addJobDetails.perks.map((perk) => (
                <span
                  key={perk}
                  className="flex items-center bg-[#E6E6E6] text-[11px] text-[#0008] px-4 py-2 rounded-xl"
                >
                  {perk}
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handlePerkRemoval(perk)}
                  >
                    &times;
                  </button>
                </span>
              ))}
              <input
                type="text"
                value={newPerk}
                onChange={(e) => setNewPerk(e.target.value)}
                placeholder="Add a new perk"
                className="placeholder:text-[#00000095] placeholder:text-[11px] md:text-[11px] bg-[#E6E6E6] p-3 rounded-xl outline-none leading-[24px]"
              />
              <button
                type="button"
                onClick={handlePerkAddition}
                className="bg-blue-500 mt-3 text-white text-[1.5rem] font-medium flex items-center justify-center rounded-full h-7 w-7"
              >
                +
              </button>
            </div>
            {errors.perks && (
              <p className="text-red-500 text-sm">{errors.perks}</p>
            )}
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[14px] text-white text-center py-4 md:py-6 rounded-md disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save & Continue"}
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

export default AddJobDetails;