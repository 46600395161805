import tickIcon from "../../../assets/imgs/tick.svg";
import crossIcon from "../../../assets/imgs/crossImg.svg";

interface PriceProps {
  heading?: string;
  originalPrice?: string;
  discount?: string;
  discountedPrice?: string;
  duration?: string;
  features?: string[];
  notIncluded?: string[];
  isRecommended?: boolean;
}

function PriceCard({
  heading,
  originalPrice,
  discount,
  discountedPrice,
  duration,
  features = [],
  notIncluded = [],
  isRecommended,
}: PriceProps) {
  return (
    <>
      {isRecommended ? (
        <div className="w-[183px] border-2 border-customBlue shadow-xl rounded text-customBlue p-[9px] pl-[6px] relative lg:w-[600px] lg:h-[700px] lg:p-[1rem]">
          <div className="w-full h-[26px] lg:h-[44px]  bg-customBlue absolute top-0 left-0 flex justify-center items-center">
            <p className="text-[13px] lg:text-[20px] text-white text-center pb-[7px]">
              Recommended
            </p>
          </div>
          <h3 className="text-[12px] font-semibold leading-[16px] pt-[22px] m-0 lg:pt-12 lg:leading-[40px] lg:text-[28px]">
            {heading}
          </h3>
          <p className="text-[10px] text-gray-700 m-0 pr-0 leading-[14px] pt-2 lg:text-[22px] lg:leading-[30px] lg:pb-4">
            Original Price{" "}
            <span className="text-black text-[#00000099] font-semibold text-[10px] lg:text-[22px] line-through">
              {originalPrice}
            </span>{" "}
            <span className="text-[6px] text-[#28A71B] lg:text-[18px]">
              flat {discount} off
            </span>
          </p>
          <h2 className="text-[20px] font-semibold pb-[4px] lg:text-[30px]">
            {discountedPrice}{" "}
            <span className="text-[12px] font-normal lg:text-[1.2rem]">
              ({duration})
            </span>
          </h2>
          <hr className="pb-2" />
          {features.map((e, index) => (
            <div
              key={index}
              className="flex items-center gap-[5px] lg:gap-[15px]"
            >
              <img
                className="h-[5px] lg:h-[20px]"
                src={tickIcon}
                alt="tick_icon"
              />
              <p className="text-[8px] m-[2px] leading-[12px] text-black text-opacity-70 lg:text-[20px] lg:leading-[40px]">
                {e}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="w-[183px]  border rounded text-customBlue p-[9px] pl-[6px] pt-[31px] lg:pt-[40px] lg:w-[600px] lg:h-[700px] lg:p-[1rem] lg:pt-[63px] lg:pr-[2rem]">
          <h3 className="text-[12px] font-semibold leading-[16px] m-0 lg:leading-[40px] lg:text-[28px]">
            {heading}
          </h3>
          <p className="text-[10px] text-gray-700 m-0 pr-0 leading-[14px] pt-2 lg:text-[22px] lg:leading-[30px] lg:pb-4">
            Original Price{" "}
            <span className="text-black text-[#00000099] font-semibold text-[10px] lg:text-[22px] line-through">
              {originalPrice}
            </span>{" "}
            <span className="text-[6px] text-[#28A71B] lg:text-[18px]">
              flat {discount} off
            </span>
          </p>
          <h2 className="text-[20px] font-semibold pb-1  lg:text-[30px]">
            {discountedPrice}{" "}
            <span className="text-[12px] font-normal lg:text-[1.2rem]">
              ({duration})
            </span>
          </h2>
          <hr className="pb-2" />
          {features.map((e, index) => (
            <div
              key={index}
              className="flex items-center lg:gap-[15px] gap-[5px]"
            >
              <img
                className="h-[5px] lg:h-[20px]"
                src={tickIcon}
                alt="tick_icon"
              />
              <p className="text-[8px] m-[2px] leading-[12px] text-black text-opacity-70 lg:text-[20px] lg:leading-[40px]">
                {e}
              </p>
            </div>
          ))}
          {notIncluded.map((e, index) => (
            <div
              key={index}
              className="flex items-center lg:gap-[15px] gap-[5px]"
            >
              <img
                className="h-[5px] lg:h-[20px]"
                src={crossIcon}
                alt="cross_icon"
              />
              {index === 0 ? (
                <p className="text-[8px] text-left m-[2px] leading-[12px] text-black text-opacity-70 top-[2px] relative lg:text-[20px] lg:leading-[40px]">
                  {e}
                </p>
              ) : (
                <p className="text-[8px] text-left m-[2px] mt-[3px] leading-[12px] text-black text-opacity-70 relative lg:text-[20px] lg:leading-[40px]">
                  {e}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default PriceCard;
