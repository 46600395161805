import scannerBg from "../assets/imgs/Scanner-bg.svg";
import qrScanner from "../assets/imgs/QrScanner.png";
import xLogo from "../assets/imgs/xlogoBlue.png";
import { Link } from "react-router-dom";

function XampusId() {
  return (
    <>
      <div
        className="h-screen flex text-white justify-center items-center"
        style={{
          backgroundImage: `url(${scannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col px-4 gap-5 text-white text-center justify-center items-center">
          <Link to="/"><img src={xLogo} alt="" className="h-20 w-20 rounded-md" /></Link> 
          <h1 className="text-[2.5rem] font-bold">Xampus</h1>
          <p className="text-[1.6rem]">You've just scanned Xampus ID!</p>
          <img src={qrScanner} alt="" className="my-5 h-40 w-45" />
          <p className="text-[1.5rem]">Exciting features are on the way, stay tunned!</p>
        </div>
      </div>
    </>
  );
}

export default XampusId;
