import scholarGirl from "../../assets/imgs/scholarGirl.svg";
import hatImg from "../../assets/imgs/hat.svg";
import arrowImg from "../../assets/imgs/arrow.svg";
import electronImg from "../../assets/imgs/electron.svg";
import cpuImg from "../../assets/imgs/cpu.svg";
import bagImg from "../../assets/imgs/bag.svg";
import Button from "../resume-services-v3/Button/Button";

function CourseHighlights() {
  const highlights = [
    {
      icon: hatImg,
      highlight:
        "Master the fundamentals of React Native with real-world applications",
    },
    {
      icon: arrowImg,
      highlight:
        "Build stunning cross-platform mobile apps for Android and iOS",
    },
    {
      icon: electronImg,
      highlight:
        "Gain expertise in popular tools like Redux, and React Navigation",
    },
    {
      icon: cpuImg,
      highlight: "Work on capstone projects to showcase your skills to clients",
    },
    {
      icon: bagImg,
      highlight:
        "Exclusive Internship hands-on experience in a professional environment",
    },
  ];

  return (
    <div className="lg:h-[100vh]">
      <h1 className="text-customBlue text-[24px] lg:text-[30px] font-semibold pt-3 pb-5 px-[70px] text-center">
        Course Highlights
      </h1>
      <section className="pb-[50px] px-2">
        <div className="flex gap-3 lg:justify-evenly items-center lg:h-[70vh]">
          {/* Girl with Button */}
          <div className="flex flex-col items-center">
            <div className="w-[150px] mb-[30px] lg:h-[30rem] lg:w-[23rem]">
              <img
                className="w-full lg:h-full lg:w-full opacity-90"
                src={scholarGirl}
                alt="scholar_girl"
              />
            </div>
          </div>

          <div className="flex flex-col gap-1 lg:gap-[3rem] lg:relative bottom-[2rem]">
            {highlights.map((e, index) => (
              <div key={index} className="flex gap-2 items-center">
                <div className="bg-[#E1F3FF] rounded w-[32px] h-5 flex lg:w-[50px] lg:h-[50px]">
                  <img
                    className="p-[2px] w-[20px] h-[20px] lg:w-[3rem] lg:h-[3rem] lg:p-[10px]"
                    src={e.icon}
                    alt="hat_image"
                  />
                </div>
                <p className="text-[12px] leading-[20px] lg:text-[24px] lg:leading-[30px]">
                  {e.highlight}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center mt-5 lg:mt-0">
          <Button
            height="h-[40px]"
            width="lg:w-[40%] px-[50px]"
            label="Download Syllabus"
            labelSize="text-[16px] lg:text-[20px]"
            onClick={() =>
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/Ads_%2FAdsPdfs%2FReact%20Native%20course.pdf?alt=media&token=c9082bae-7a2c-4d94-a891-13e8a32e3b1e",
                "_blank"
              )
            }
          />
        </div>
      </section>
    </div>
  );
}

export default CourseHighlights;
