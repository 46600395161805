import React, { useContext, useState } from "react";
import { PostContext } from "../../../contexts/hr-portal-context/Context";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/firebaseContext/Context";

interface Props {
    formIndex: () => void;
  }

// Define the type for the plan keys
type PlanType = "Classic pack" | "Premium pack" | "Super premium pack";

// Define the type for the plans object
interface PlanDetails {
  price: number;
  discount: number;
  features: string[];
}

function ChooseYourPlan({ formIndex }: Props) {
  const { addJobDetails } = useAuth();
  const navigate = useNavigate()
  const context = useContext(PostContext);

  if (!context) {
    throw new Error("AddJobDetails must be used within a PostContext.Provider");
  }

  const { postDetailsForm, setPostDetailsForm } = context;
  const [loading, setLoading] = useState(false);


  // State to keep track of the selected plan
  const [selectedPlan, setSelectedPlan] = useState<PlanType>("Premium pack");

  // Pricing details
  const plans: Record<PlanType, PlanDetails> = {
    "Classic pack": {
      price: 299,
      discount: 100,
      features: [
        "Job will be active for 15 days",
        "Lifetime Availability of Job Post Result's",
        "Higher visibility for candidate",
        "Urgently hiring tag",
        "2X fast hiring",
      ],
    },
    "Premium pack": {
      price: 599,
      discount: 100,
      features: [
        "Job will be active for 30 days",
        "Lifetime Availability of Job Post Result's",
        "Higher visibility for candidate",
        "Urgently hiring tag",
        "2X fast hiring",
      ],
    },
    "Super premium pack": {
      price: 1000,
      discount: 100,
      features: [
        "Job will be active for 15 days",
        "Lifetime Availability of Job Post Result's",
        "Higher visibility for candidate",
        "Urgently hiring tag",
        "2X fast hiring",
      ],
    },
  };

  // Calculate total price after discount
  const totalPrice =
    plans[selectedPlan].price -
    (plans[selectedPlan].price * plans[selectedPlan].discount) / 100;

  const proceed = async () => {
    setLoading(true)
    try {
      await addJobDetails(postDetailsForm)
      navigate('/hr-portal/payment')
    } catch (error) {
      throw new Error
    }finally{
      setLoading(false)
    }
  };

  return (
    <main className="bg-[#fff] flex items-center justify-center w-full my-4 rounded-xl">
      <div className="flex flex-col w-full md:w-[90%] p-6 md:p-8 lg:p-16 gap-y-8 md:gap-y-10 lg:gap-y-12">
        <div className="flex flex-col items-center justify-center">
          {/* Plans Section */}
          <div className="flex flex-col md:flex-row justify-center space-x-0 space-y-8 md:space-y-0 md:space-x-12">
            {Object.keys(plans).map((plan) => (
              <div
                key={plan}
                className={`w-[230px] ${
                  plan === "Premium pack" ? "" : "px-2 py-3 md:px-4 md:py-6"
                } bg-white rounded-lg shadow-card cursor-pointer ${
                  selectedPlan === plan
                    ? "border-[3px] border-[#24336B] scale-110 md:scale-125"
                    : ""
                }`}
                onClick={() => setSelectedPlan(plan as PlanType)}
              >
                {plan === "Premium pack" && (
                  <p className="bg-[#24336B] mt-0 pt-0 w-full text-center text-[#fff] text-[14px] rounded-t-md p-0">
                    Recommended
                  </p>
                )}
                <div
                  className={`${
                    plan === "Premium pack" ? "px-3 py-3 md:py-6" : ""
                  }`}
                >
                  <h2 className="text-[14px] font-semibold mb-0 md:mb-2">
                    {plan}
                  </h2>
                  <p className="text-[#000] text-[10px] line-through">
                    ₹{plans[plan as PlanType].price}
                  </p>
                  <div className="flex items-center gap-2 md:gap-3 pb-1 md:pb-4">
                    <p className="text-[16px] md:text-[24px] font-bold text-[#24336B] leading-[36px]">
                      Free
                    </p>
                    <p className="flex h-[16.29px] w-[59.72px] items-center justify-center bg-[#E6E6E6] font-medium text-[8px] text-[#24336B] rounded-full">
                      Offer price
                    </p>
                  </div>
                  <hr />
                  <ul className="mt-4 space-y-2">
                    {plans[plan as PlanType].features.map((feature, index) => (
                      <li
                        key={index}
                        className={`${
                          (plan === "Classic pack" && index < 3) ||
                          (plan === "Premium pack" && index < 4) ||
                          (plan === "Super premium pack" && index < 5)
                            ? "text-[#000]"
                            : "text-gray-500"
                        } text-[9px]`}
                      >
                        {(plan === "Classic pack" && index < 3) ||
                        (plan === "Premium pack" && index < 4) ||
                        (plan === "Super premium pack" && index < 5)
                          ? "✔️"
                          : "❌"}{" "}
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>

          {/* Billing Details */}
          <div className="mt-10 w-full bg-white p-6">
            <hr className="border-[1px] my-2" />
            <h3 className="text-[14px] md:text-[18px] font-semibold mb-4">
              Billing details
            </h3>
            <div className="flex justify-between text-[13px] md:text-[16px] mb-2">
              <span>{selectedPlan}</span>
              <span>₹{plans[selectedPlan].price}</span>
            </div>
            <div className="flex justify-between text-[13px] md:text-[16px] mb-2">
              <span>Discount</span>
              <span className="flex items-center gap-x-3 text-[#208EFB]">
                <p className="flex h-[21px] w-[89px] items-center justify-center bg-[#E6E6E6] font-medium text-[11px] text-[#208EFB] rounded-full">
                  {plans[selectedPlan].discount}% discount
                </p>
                ₹{plans[selectedPlan].price}
              </span>
            </div>
            <div className="flex justify-between text-[13px] md:text-[16px] mb-2">
              <span>Tax</span>
              <span>₹0</span>
            </div>
            <hr className="border-[1px] mt-4 mb-2" />
            <div className="flex justify-between font-semibold">
              <span className="text-[#24336B] text-[14px] md:text-[18px]">
                Total
              </span>
              <span className="text-[14px] md:text-[18px]">₹{totalPrice}</span>
            </div>
          </div>

          {/* Proceed Button */}
          <button
            onClick={proceed}
            disabled={loading}
            className="mt-6 px-10 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg disabled:opacity-50"
          >
            {loading ? "Processing...": `Proceed to pay ₹${totalPrice}`}
          </button>
        </div>
      </div>
    </main>
  );
}

export default ChooseYourPlan;
