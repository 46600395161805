import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDQKF5L0I0N05REh5jLoNUv5SBPOmyIddE",
  authDomain: "xampus-campus.firebaseapp.com",
  databaseURL: "https://xampus-campus-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "xampus-campus",
  storageBucket: "xampus-campus.appspot.com",
  messagingSenderId: "1069887181804",
  appId: "1:1069887181804:web:3c675d30e7ae5db252a6c9",
  measurementId: "G-FE9TZ61BXK"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
