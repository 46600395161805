import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import blueLogo from "../../../assets/imgs/xlogoBlue.png";
import mobilePng from "../../../assets/imgs/hr-portal-png.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/firebaseContext/Context";

// Importing utility functions
import {
  isValidEmail,
  isValidPassword,
  isPasswordContainNumber,
  isPasswordContainSpecialChar,
  isPasswordContainCapitalChar,
  isDisposableEmail,
} from "../../../utils/Utils"; // Adjust the import path according to your project structure

// Icons for showing/hiding password
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Helmet } from "react-helmet";

function HrAuth() {
  const { hrLogin, hrRegister, currentUser, authLoading } = useAuth();
  const navigate = useNavigate();

  const [loginPage, setLoginPage] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    terms: "",
  });
  const [loading, setLoading] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Reset form fields and errors when switching between login and sign up
    setUser({ email: "", password: "" });
    setErrors({ email: "", password: "", terms: "" });
    setAgreeTerms(false);
  }, [loginPage]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeTerms(e.target.checked);
    setErrors((prevErrors) => ({ ...prevErrors, terms: "" }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateForm = () => {
    const newErrors: typeof errors = {
      email: "",
      password: "",
      terms: "",
    };

    if (!user.email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(user.email)) {
      newErrors.email = "Invalid email format";
    } else if (isDisposableEmail(user.email)) {
      newErrors.email = "Disposable email addresses are not allowed";
    }

    if (!user.password) {
      newErrors.password = "Password is required";
    } else if (!isPasswordContainNumber(user.password)) {
      newErrors.password = "Password must contain at least one number";
    } else if (!isPasswordContainSpecialChar(user.password)) {
      newErrors.password = "Password must contain at least one special character";
    } else if (!isPasswordContainCapitalChar(user.password)) {
      newErrors.password = "Password must contain at least one capital letter";
    } else if (!isValidPassword(user.password)) {
      newErrors.password = "Password must be at least 8 characters long"
    }

    if (!loginPage && !agreeTerms) {
      newErrors.terms = "You must agree to the terms and conditions";
    }

    setErrors(newErrors);

    // Return true if no errors found
    return !newErrors.email && !newErrors.password && !newErrors.terms;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (loginPage) {
        if (!user.email) {
          setErrors({
            ...errors,
            email:"Email is required"
          })
          return
        } else if (!isValidEmail(user.email)) {
          setErrors({
            ...errors,
            email:"Invalid email format"
          })
          return
        }
        if (!user.password) {
          setErrors({
            ...errors,
            password:"Password is required"
          })
          return
        }
        await hrLogin(user.email, user.password);
        navigate("/hr-portal/add-post");
      } else {
        
        if (!validateForm()) {
          return;
        }
        await hrRegister(user.email, user.password);
        navigate("/hr-portal/add-post");
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(!authLoading && currentUser){
      navigate('/hr-portal/homepage')
    }
  }, [currentUser, authLoading])

  return (
    <>
      <Helmet>
        <title>Professional HR-Portal: Sign Up for a New Experience!</title>
        <meta name="keywords" content="Sign in, Sign up, Register, Hr portal" />
      </Helmet>
      <div className="min-h-screen overflow-hidden">
        <div className="bg-white w-full grid grid-cols-1 md:grid-cols-[1.2fr_2fr]">
          <div className="relative left-box bg-[#24336B] hidden md:flex flex-col items-center">
            <div className="md:w-[75%]">
              <div className="flex items-start my-[3rem]">
                <a href="/">
                  <img
                    src={blueLogo}
                    className="h-[2rem] md:h-[3rem] rounded-md shadow-custom"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="text-start">
                <h2 className="font-semibold md:text-[32px] text-[#fff]">
                  {loginPage
                    ? "Glad you're back! Log in to continue exploring"
                    : "Find the right candidate. Fast & Free"}
                </h2>
                <p className="font-medium md:text-[20px] text-[#ffffffc1]">
                  Post your jobs to reach thousands of jobseekers for free
                </p>
              </div>
            </div>

            <div className="">
              <img
                src={mobilePng}
                alt="mobile png"
                className="absolute bottom-0 md:left-[15%] lg:left-[25%]"
              />
            </div>
          </div>

          <div className="right-box min-h-screen w-full flex flex-col justify-center items-center">
            <div className="text-center ">
              <h2 className="text-[#0A1E67] font-semibold text-[24.35px]">
                {loginPage ? "Welcome Back" : "Join Xampus TalentPortal"}
              </h2>
              <p className="text-[#000000] font-semibold text-lg md:text-[16px] leading-[24px]">
                {loginPage
                  ? "Please enter your details"
                  : "Create your new account"}
              </p>
            </div>

            <form
              onSubmit={handleSubmit}
              className="mb-2 px-6 md:px-0 w-full md:w-[25rem]"
            >
              <div className="space-y-5 md:space-y-8">
                {loginPage ? (
                  <p className="text-[12px] leading-[18.06px] text-[#24336B] font-medium">
                    *Users can also Sign In using their existing Xampus
                    credentials
                  </p>
                ) : (
                  <p className="text-[12px] leading-[18.06px] text-[#24336B] font-medium">
                    *Users can also Sign Up using their existing Xampus
                    credentials
                  </p>
                )}

                <div className="flex flex-col">
                  <label
                    htmlFor="email"
                    className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={user.email}
                    onChange={handleInputChange}
                    placeholder="e. g. johnmilton1@gmail.com"
                    className="input"
                  />
                  {errors.email && (
                    <p className="text-[12px] text-red-600">{errors.email}</p>
                  )}
                </div>
                <div className="flex flex-col relative">
                  <label
                    htmlFor="password"
                    className="text-[#1E1E1E] text-[12.63px] font-medium tracking-tight mb-[0.5rem]"
                  >
                    {loginPage ? "Enter your password" : "Set Password"}
                  </label>
                  <div className="flex items-center border border-[#1E1E1E] rounded-lg focus-within:ring-2 focus-within:ring-blue-500 transition duration-300">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={user.password}
                      onChange={handleInputChange}
                      placeholder={
                        loginPage ? "*********" : "At least 8 characters"
                      }
                      className="placeholder:text-[#000] placeholder:text-[10.63px] p-3 md:p-4 rounded-lg outline-none w-full md:text-[10.64px] leading-[18.09px]"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="text-gray-600 mr-4"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>

                  {loginPage ? (
                    ""
                  ) : (
                    <p className="text-[12px] text-[#898989] font-medium leading-5">
                      Password should contain at least 8 characters <br />{" "}
                      Special Character, Capital letter, Number
                    </p>
                  )}
                  {errors.password && (
                    <p className="text-[12px] text-red-600 leading-4">
                      {errors.password}
                    </p>
                  )}
                </div>
                {loginPage ? (
                  <div>
                    <Link
                      to="/auth/hr-portal/forgot-password"
                      className="text-[14px] font-medium text-[#139AFD] hover:underline"
                      aria-label="Forgot password"
                    >
                      Forgot password?
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center gap-x-2">
                      <input
                        type="checkbox"
                        id="terms"
                        className="h-5 w-5 text-gray-600 border-gray-300 rounded"
                        checked={agreeTerms}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="terms"
                        className="text-gray-700 text-[12px]"
                      >
                        I agree to the{" "}
                        <Link to="/termsofservice" className="text-[#139AFD]">
                          Terms & conditions
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacypolicy" className="text-[#139AFD]">
                          Privacy Policy
                        </Link>{" "}
                        of Xampus
                      </label>
                    </div>
                    {errors.terms && (
                      <p className="text-[12px] text-red-600">{errors.terms}</p>
                    )}
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[14px] text-white text-center py-4 rounded-md disabled:opacity-50"
                  disabled={loading}
                >
                  {loading
                    ? loginPage
                      ? "Signing In..."
                      : "Signing Up..."
                    : loginPage
                    ? "Sign In"
                    : "Sign Up"}
                </button>
              </div>
            </form>
            {loginPage ? (
              <p className="text-center text-[14px] text-[#000000c8] font-medium">
                Don’t have an account?{" "}
                <button
                  onClick={() => setLoginPage(false)}
                  className="text-[#139AFD]"
                >
                  {" "}
                  Sign up Now!
                </button>
              </p>
            ) : (
              <p className="text-center text-[14px] text-[#000000c8] font-medium">
                Already have an account?{" "}
                <button
                  onClick={() => setLoginPage(true)}
                  className="text-[#139AFD]"
                >
                  Sign In Now!
                </button>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HrAuth;
