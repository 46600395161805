import blueStar from "../../../assets/imgs/grayStar.svg";
import blurStar from "../../../assets/imgs/grayStarLight.svg";
import "@fontsource-variable/lexend-peta";

interface CardProps {
  imgSrc?: string;
  name?: string;
  review?: string;
  stars?: number;
}

function ReviewCardBox({ imgSrc, name, review, stars = 0 }: CardProps) {
  const filledStars = Math.min(stars, 5);
  const totalStars = 5;
  return (
    <div className="w-[120px] h-[220px] rounded-md shadow-xl relative lg:w-[400px] lg:h-[500px]">
      <div className="flex flex-col bg-[#EAEAEA] p-2 h-[150px] lg:h-[300px] lg:text-center">
        <p className="text-[2rem] absolute font-lexend lg:text-[4.2rem] lg:pt-[2rem]">
          "
        </p>
        <p className="card-sm-text pt-6 lg:text-[1rem] lg:leading-[2.5rem] lg:pt-[4rem]">
          {review}
        </p>

        <div className="absolute top-[60%] lg:top-[40%] flex flex-col items-center right-1 w-full">
          <img
            className="h-10 rounded-full lg:h-40"
            src={imgSrc}
            alt="menteeImg"
          />
          <p className="text-xs lg:text-[1.2rem] lg:py-3 ">{name}</p>
        </div>

        <div className="flex my-1 absolute w-full top-[88%] left-[40px] lg:left-[30%] lg:top-[80%]">
          {Array.from({ length: totalStars }).map((_, index) => (
            <img
              key={index}
              src={index < filledStars ? blueStar : blurStar}
              alt={index < filledStars ? "Filled star" : "Empty star"}
              className="h-[7px] w-[7px] lg:h-10 lg:w-8"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReviewCardBox;

{
  /* <div className="flex flex-col justify-between items-center h-[120px] text-slate-600 lg:pb-[400px] border ">
  <div className="bg-[#EAEAEA] relative">
    <p className="p-0 m-0 text-[4rem] absolute bottom-0">"</p>
    <p className="text-[8px] lg:text-[1.2rem] mt-[6px] lg:leading-10 leading-3 text-center">
      {review}
    </p>
  </div>

  <img className="h-8 rounded-full lg:h-40" src={imgSrc} alt="" />
  <p className="text-xs lg:text-[1.2rem] lg:py-3">{name}</p>

  <div className="flex my-1">
    {Array.from({ length: totalStars }).map((_, index) => (
      <img
        key={index}
        src={index < filledStars ? blueStar : blurStar}
        alt={index < filledStars ? "Filled star" : "Empty star"}
        className="h-[10px] w-[10px] lg:h-10 lg:w-8"
      />
    ))}
  </div>
</div>; */
}
